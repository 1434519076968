import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import {
    CircularProgress,
    Typography,
    Card,
    CardContent,
    CardHeader,
    TableCell,
    TableRow,
    TableHead,
    TableBody,
    Grid,
    TableContainer,
    Table,
    Paper,
    Button,
    Alert,
} from '@mui/material';
import {
    formatDate,
    UploadDocumentButton,
    formatPrice,
    formatUnit,
    DownloadDocumentButton,
    CreateInvoice,
    DisableDialog
} from '../../components/Utils';


function DeliveryNoteProviderDetails() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const { api } = useAuth();
    const [error, setError] = useState(null);

    const [deliveryNoteData, setDeliveryNoteData] = useState(null);

    const [openDisableDialog, setOpenDisableDialog] = useState(false);
    const [disableId, setDisableId] = useState(null);
    const [navigateToWhenDisabled, setNavigateToWhenDisabled] = useState(false);
    const [endpointToDisable, setEndpointToDisable] = useState(false);

    const fetchDeliveryNote = useCallback(async () => {
        try {
            const result = await api().get(`/delivery_note_providers/${id}`);
            setDeliveryNoteData(result.data);
            setLoading(false);
        } catch (error) {
            setError("Error obteniendo albarán");
            setLoading(false);
        }
    }, [api, id]);

    useEffect(() => {
        fetchDeliveryNote();
    }, []);

    const handleOpenDisableDialog = (itemId, endpoint, navigateTo) => {
        setDisableId(itemId);
        setEndpointToDisable(endpoint);
        setNavigateToWhenDisabled(navigateTo);
        setOpenDisableDialog(true);
    };

    const invoiceData = deliveryNoteData?.invoices?.[0];
    const invoiceBase = invoiceData ? invoiceData.base_price || 0 : 0;
    const total = (invoiceData?.total_price || 0);
    const vatAmount = (invoiceData?.total_price || 0) - invoiceBase;

    const deliveryNoteTotal = deliveryNoteData?.delivery_note_provider_order_details.reduce((acc, provider_order) => {
        const totalPrice = provider_order.price;
        return acc + totalPrice;
    }, 0);

    return (
        <div style={{ padding: 20 }}>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            ) : (
                <div>
                    {/* Mostrar el mensaje de error si existe */}
                    {error && (
                        <Alert severity="error" style={{ marginBottom: '20px' }}>
                            {error}
                        </Alert>
                    )}

                    <Card sx={{ margin: 'auto' }}>
                        <CardHeader
                            title={
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', padding: '20px 0' }}>
                                    <span style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                                        {`Albarán: ${deliveryNoteData.code} | Fecha: ${formatDate(deliveryNoteData.dn_date) || '-'}`}
                                    </span>
                                    <Grid item xs={2}
                                        style={{
                                            position: 'absolute',
                                            right: '16px',
                                        }}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleOpenDisableDialog(
                                                id, 
                                                '/delivery_note_providers/disable', 
                                                `/provider_orders/${deliveryNoteData.provider_order.id}/details`)}
                                            style={{ backgroundColor: 'red', marginRight: '16px' }}
                                        >
                                            Eliminar
                                        </Button>
                                    </Grid>
                                </div>
                            }
                            sx={{
                                backgroundColor: '#f5f5f5',
                                textAlign: 'center',
                                fontWeight: 'bold',
                                padding: '16px 0'
                            }}
                        />
                        {/* Diálogo de confirmación de eliminación */}
                        <DisableDialog
                            open={openDisableDialog}
                            onClose={() => setOpenDisableDialog(false)}
                            endpoint={endpointToDisable}
                            disableId={disableId}
                            navigateTo={navigateToWhenDisabled}
                        />
                        <CardContent>
                            <Grid container spacing={3}>
                                {/* Información del Proveedor */}
                                <Grid item xs={12} md={6}>
                                    <Paper elevation={3} sx={{ padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '8px', marginBottom: '20px', textAlign: 'left' }}>
                                        <Typography variant="h6" sx={{ mb: 3, fontWeight: 'bold', fontSize: '16px' }}>
                                            Información del Proveedor
                                        </Typography>
                                        <Typography variant="body2" sx={{ fontSize: '14px', marginBottom: '10px' }}>
                                            <strong>Código: </strong>
                                            <Link to={`/providers/${deliveryNoteData.provider_order.provider_id}/details`}>
                                                {deliveryNoteData.provider_order.provider.code || '-'} <br />
                                            </Link>
                                            <strong>Nombre: </strong> {deliveryNoteData.provider_order.provider.name || '-'} <br />
                                            <strong>Email: </strong> {deliveryNoteData.provider_order.provider.email || '-'} <br />
                                            <strong>Teléfono: </strong> {deliveryNoteData.provider_order.provider.telephone_number || '-'} <br />
                                            <strong>CIF: </strong> {deliveryNoteData.provider_order.provider.cif || '-'} <br />
                                        </Typography>
                                    </Paper>
                                </Grid>

                                {/* Información de la Factura */}
                                <Grid item xs={12} md={6}>
                                    <Paper elevation={3} sx={{ padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '8px', marginBottom: '20px', textAlign: 'left' }}>
                                        <Typography variant="h6" sx={{ mb: 3, fontWeight: 'bold', fontSize: '16px' }}>
                                            Información de la Factura
                                        </Typography>
                                        {invoiceData ? (
                                            <Typography variant="body2" sx={{ fontSize: '14px', marginBottom: '10px' }}>
                                                <strong>Código: </strong>
                                                <Link to={`/invoices_provider/${invoiceData.id}/details`}>
                                                    {invoiceData.code} <br />
                                                </Link>
                                                <strong>Fecha de la Factura: </strong> {formatDate(invoiceData.invoice_date) || '-'} <br />
                                                <strong>Base imponible: </strong> {invoiceBase.toFixed(2)}€ <br />
                                                <strong>IVA: </strong> {invoiceData.vat}% ({vatAmount.toFixed(2)}€) <br />
                                                <strong>Total: </strong> {total.toFixed(2)}€ <br />
                                            </Typography>
                                        ) : (
                                            <CreateInvoice
                                                isProviderInvoice={true}
                                                fetch={fetchDeliveryNote}
                                                deliveryNotes={[id]}
                                                sx={{ maxWidth: '120px' }}
                                            />
                                        )}
                                    </Paper>
                                </Grid>
                            </Grid>

                            {/* Tabla de productos del Albarán */}
                            <Paper elevation={3} sx={{ padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '8px', marginTop: '20px' }}>
                                <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>
                                    Albarán:
                                    {deliveryNoteData.code || '-'}
                                </Typography>

                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><strong>Producto</strong></TableCell>
                                                <TableCell><strong>Cantera</strong></TableCell>
                                                <TableCell><strong>Cantidad</strong></TableCell>
                                                <TableCell><strong>Precio Unitario</strong></TableCell>
                                                <TableCell><strong>Total</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {deliveryNoteData.delivery_note_provider_order_details.map((order) => {
                                                const product = order.provider_order_detail.product_group;
                                                const totalPrice = order.amount * order.provider_order_detail.unit_price;
                                                const quarryName = order.provider_order_detail?.quarry?.name ?? 'Sin cantera';

                                                return (
                                                    <TableRow key={order.id}>
                                                        <TableCell>{product.name || '-'}</TableCell>
                                                        <TableCell>{quarryName}</TableCell>
                                                        <TableCell>{order.amount} {formatUnit(product.unit)}</TableCell>
                                                        <TableCell>{formatPrice(order.provider_order_detail.unit_price)}</TableCell>
                                                        <TableCell>{formatPrice(totalPrice)}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                            {/* Información adicional del total */}
                                            <TableRow>
                                                <TableCell rowSpan={4} colSpan={3}></TableCell>
                                                <TableCell><strong>Base imponible</strong></TableCell>
                                                <TableCell>{formatPrice(deliveryNoteTotal)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell><strong>Total</strong></TableCell>
                                                <TableCell><strong>{formatPrice(deliveryNoteTotal)}</strong></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>

                            {/* Espacio y alineación de UploadDocument */}
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                {deliveryNoteData.documents && deliveryNoteData.documents.length > 0 && (() => {
                                    const activeDocument = deliveryNoteData.documents.find(doc => !doc.disabled);
                                    return activeDocument ? (
                                        <DownloadDocumentButton documentId={activeDocument.id} documentName={activeDocument.name} />
                                    ) : null;
                                })()}
                                <UploadDocumentButton
                                    to={'delivery_note_provider_id'}
                                    id={id}
                                    fetch={fetchDeliveryNote}
                                >
                                </UploadDocumentButton>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            )}
        </div>
    );
}

export default DeliveryNoteProviderDetails;
