import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { useSnackbar } from '../../components/SnackbarProvider';
import {
    CircularProgress,
    Typography,
    Button,
    Card,
    CardContent,
    CardHeader,
    TableCell,
    TableRow,
    TableHead,
    TableBody,
    Grid,
    TableContainer,
    Table,
    Paper,
    Alert,
} from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
    formatDate,
    formatPrice,
    formatTime,
    formatOfferStatus,
    CreatePdf,
    handleApiError,
    DisableDialog
} from '../../components/Utils';

dayjs.extend(utc);

function OfferDetails() {
    const { id } = useParams();
    const { api } = useAuth();
    const showSnackbar = useSnackbar();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [offer, setOffer] = useState(null);
    const [statusUpdateError, setStatusUpdateError] = useState(null);

    const [openDisableDialog, setOpenDisableDialog] = useState(false);
    const [disableId, setDisableId] = useState(null);
    const [navigateToWhenDisabled, setNavigateToWhenDisabled] = useState(false);
    const [endpointToDisable, setEndpointToDisable] = useState(false);

    const fetchOffer = useCallback(async () => {
        try {
            const result = await api().get(`/offers/${id}`);
            setOffer(result.data);
            setLoading(false);
        } catch (error) {
            setError("Error obteniendo oferta");
            setLoading(false);
        }
    }, [api, id]);

    const handleAcceptOffer = async () => {
        let error = null;
        try {
            setLoading(true);
            const result = await api().post(`/offers/${id}/accept`);
            setOffer(result.data);
            showSnackbar('Oferta aceptada correctamente');
        } catch (e) {
            error = handleApiError(e, 'Error inesperado aceptando oferta.');
        } finally {
            if (error) showSnackbar(error, 'error');
            setLoading(false);
        }
    };

    const handleDeclineOffer = async () => {
        let error = null;
        try {
            setLoading(true);
            const result = await api().post(`/offers/${id}/decline`);
            setOffer(result.data);
            showSnackbar('Oferta rechazada correctamente');
        } catch (e) {
            error = handleApiError(e, 'Error inesperado rechazando oferta.');
        } finally {
            if (error) showSnackbar(error, 'error');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOffer();
    }, []);

    const handleOpenDisableDialog = (itemId, endpoint, navigateTo) => {
        setDisableId(itemId);
        setEndpointToDisable(endpoint);
        setNavigateToWhenDisabled(navigateTo);
        setOpenDisableDialog(true);
    };

    const total = offer ? offer.offer_details.reduce((acc, detail) => acc + detail.total_price, 0) : 0;

    return (
        <div style={{ padding: 20 }}>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            ) : (
                <div>
                    {/* Mostrar el mensaje de error si existe */}
                    {error && (
                        <Alert severity="error" style={{ marginBottom: '20px' }}>
                            {error}
                        </Alert>
                    )}
                    {/* Mostrar el mensaje de error al marcar como recibido */}
                    {statusUpdateError && (
                        <Alert severity="error" style={{ marginBottom: '20px' }}>
                            {statusUpdateError}
                        </Alert>
                    )}

                    <Card sx={{ margin: 'auto' }}>
                        <CardHeader
                            title={(
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', padding: '20px 0' }}>
                                    <span style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                                        {`Oferta: ${offer.code} | Fecha: ${formatDate(offer.offer_date) || '-'} ${formatTime(offer.offer_date) || '-'}`}
                                    </span>

                                    <Grid item xs={2}
                                        style={{
                                            position: 'absolute',
                                            right: '16px', 
                                        }}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleOpenDisableDialog(id, '/offers/disable', '/offers/list')}
                                            style={{ backgroundColor: 'red', marginRight: '16px' }} // Margen derecho para separación
                                        >
                                            Eliminar
                                        </Button>
                                    </Grid>
                                </div>
                            )}
                            sx={{
                                backgroundColor: '#f5f5f5',
                                padding: '16px 0'
                            }}
                        />

                        {/* Diálogo de confirmación de eliminación */}
                        <DisableDialog
                            open={openDisableDialog}
                            onClose={() => setOpenDisableDialog(false)}
                            endpoint={endpointToDisable}
                            disableId={disableId}
                            navigateTo={navigateToWhenDisabled}
                        />
                        <CardContent>
                            <Grid container spacing={3}>
                                {/* Información del Cliente */}
                                <Grid item xs={12} md={6}>
                                    <Paper elevation={3} sx={{ padding: '10px', backgroundColor: '#f9f9f9', bofferRadius: '8px', marginBottom: '20px', textAlign: 'left' }}>
                                        <Typography variant="h6" sx={{ mb: 3, fontWeight: 'bold', fontSize: '16px' }}>
                                            Información del Cliente
                                        </Typography>
                                        <Typography variant="body2" sx={{ fontSize: '14px', marginBottom: '10px' }}>
                                            <strong>Código: </strong>
                                            <Link to={`/clients/${offer.client_id}/details`}>
                                                {offer.client.code || '-'} <br />
                                            </Link>
                                            <strong>Nombre: </strong> {offer.client.name || '-'} <br />
                                            <strong>Email: </strong> {offer.client.email || '-'} <br />
                                            <strong>Teléfono: </strong> {offer.client.telephone_number || '-'} <br />
                                            <strong>CIF: </strong> {offer.client.cif || '-'} <br />
                                        </Typography>
                                    </Paper>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Paper elevation={3} sx={{ padding: '10px', backgroundColor: '#f9f9f9', bofferRadius: '8px', marginBottom: '20px' }}>
                                        <Grid container spacing={3}>
                                            {/* Estado de la oferta */}
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="h6" sx={{ mb: 3, fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>
                                                    Estado de la oferta
                                                </Typography>
                                                <Typography variant="body2" sx={{ fontSize: '14px', marginBottom: '10px', textAlign: 'center' }}>
                                                    {formatOfferStatus(offer.offer_status) || '-'}
                                                </Typography>
                                                {offer.offer_status === "pending" && (
                                                    <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                                                        <Button variant="contained" color="success" onClick={handleAcceptOffer}>
                                                            Aceptar
                                                        </Button>
                                                        <Button variant="contained" color="error" onClick={handleDeclineOffer}>
                                                            Rechazar
                                                        </Button>
                                                    </div>
                                                )}
                                            </Grid>

                                            {/* Generar PDF */}
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="h6" sx={{ mb: 3, fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>
                                                    Generar PDF
                                                </Typography>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <CreatePdf endpoint={`/offers/${id}/generate-pdf`}>
                                                        <Button variant="outlined" color="secondary" sx={{ width: '100%' }}>
                                                            Crear PDF
                                                        </Button>
                                                    </CreatePdf>
                                                </div>
                                            </Grid>


                                        </Grid>
                                    </Paper>
                                </Grid>


                            </Grid>

                            {/* Tabla de productos de la oferta */}
                            <Paper elevation={3} sx={{ padding: '10px', backgroundColor: '#f9f9f9', bofferRadius: '8px', marginTop: '20px' }}>
                                <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>
                                    Oferta: {offer.code || '-'}
                                </Typography>

                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><strong>Producto</strong></TableCell>
                                                <TableCell><strong>Cantera</strong></TableCell>
                                                <TableCell><strong>Cantidad</strong></TableCell>
                                                <TableCell><strong>Precio Unitario</strong></TableCell>
                                                <TableCell><strong>Total</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {offer.offer_details.map((detail) => (
                                                <TableRow key={detail.id}>
                                                    <TableCell>{detail.product_name || '-'}</TableCell>
                                                    <TableCell>{detail.product.quarry.name || '-'}</TableCell>
                                                    <TableCell>{detail.quantity} {detail.unit}</TableCell>
                                                    <TableCell>{formatPrice(detail.unit_price)}</TableCell>
                                                    <TableCell>{formatPrice(detail.total_price)}</TableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow>
                                                <TableCell rowSpan={4} colSpan={3}></TableCell>
                                                <TableCell><strong>Total</strong></TableCell>
                                                <TableCell><strong>{formatPrice(total)}</strong></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </CardContent>
                    </Card>
                </div>
            )
            }
        </div >
    );
}

export default OfferDetails;
