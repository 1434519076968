import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { Box } from '@mui/material';
import Navbar from './components/NavBar'
import React from "react";
import Home from './pages/Home'
import SignIn from './pages/login/Sign-in';
import SignUp from './pages/login/Sign-up';
import EmailResetPass from './pages/login/EmailResetPass';
import ResetPass from './pages/login/ResetPass';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider } from './pages/login/OAuth';
import './App.css';
import { SnackbarProvider } from './components/SnackbarProvider';
import './App.css';

import QuarryDetails from './pages/products/QuarryDetails';
import ListQuarries from './pages/products/ListQuarries';
import ListProducts from './pages/products/ListProducts';
import NewProduct from './pages/products/NewProduct';
import ProductDetails from './pages/products/ProductDetails';

import ListClients from './pages/clients/ListClients';
import NewClient from './pages/clients/NewClient';
import ClientDetails from './pages/clients/ClientDetails';

import ListBuildings from './pages/buildings/ListBuildings';
import NewBuilding from './pages/buildings/NewBuilding';
import BuildingDetails from './pages/buildings/BuildingDetails';

import NewTruck from './pages/trucks/NewTruck';
import ListTrucks from './pages/trucks/ListTrucks';
import TruckDetails from './pages/trucks/TruckDetails';

import ListDeliveryNotes from './pages/deliveryNotes/ListDeliveryNotes';
import NewDeliveryNote from './pages/deliveryNotes/NewDeliveryNote';
import DeliveryNoteDetails from './pages/deliveryNotes/DeliveryNotesDetails';

import ListProviders from './pages/providers/ListProviders';
import ProviderDetails from './pages/providers/ProviderDetails';
import NewProvider from './pages/providers/NewProvider';

import ListTrailers from './pages/trailers/ListTrailers';
import TrailerDetails from './pages/trailers/TrailerDetails';
import NewTrailer from './pages/trailers/NewTrailer';

import NewTruckDriver from './pages/truckDriver/NewTruckDriver';
import ListTruckDrivers from './pages/truckDriver/ListTruckDrivers';
import TruckDriverDetails from './pages/truckDriver/TruckDriverDetails';

import NewOrder from './pages/orders/NewOrder';
import ListOrders from './pages/orders/ListOrders';
import OrderDetails from './pages/orders/OrderDetails';

import NewOffer from './pages/offers/NewOffer';
import ListOffers from './pages/offers/ListOffers';
import OfferDetails from './pages/offers/OfferDetails';

import ListInvoices from './pages/invoices/ListInvoices';
import InvoiceDetails from './pages/invoices/InvoiceDetails';
import InvoiceProviderDetails from './pages/invoices/InvoiceProviderDetails';

import NewProviderOrder from './pages/providerOrders/NewProviderOrder';
import ListProviderOrders from './pages/providerOrders/ListProviderOrders';
import ProviderOrderDetails from './pages/providerOrders/ProviderOrderDetails';
import DeliveryNoteProviderDetails from './pages/providerOrders/DeliveryNoteProviderDetails';

import UserEdit from './pages/users/UserEdit';

function App() {
  return (
    <>
      <BrowserRouter>
        <SnackbarProvider>
          <AuthProvider>
            <Navbar />
            <Box sx={{ marginTop: 10, marginLeft: 10, marginBottom: 10, marginRight: 5 }}>
              <Routes>
                <Route path='/sign-in' element={<SignIn />} />
                <Route path='/sign-up' element={<SignUp />} />
                <Route path='/reset-pass' element={<EmailResetPass />} />
                <Route path='/reset-pass/:token' element={<ResetPass />} />

                <Route element={<ProtectedRoute />}>

                  <Route path='/products/new' element={<NewProduct />} />
                  <Route path='/products/list' element={<ListProducts />} />
                  <Route path='/products/:id/details' element={<ProductDetails />} />
                  <Route path='/quarries/list' element={<ListQuarries />} />
                  <Route path='/quarries/:id/details' element={<QuarryDetails />} />

                  <Route path='/clients/new' element={<NewClient />} />
                  <Route path='/clients/list' element={<ListClients />} />
                  <Route path='/clients/:id/details' element={<ClientDetails />} />

                  <Route path='/buildings/new' element={<NewBuilding />} />
                  <Route path='/buildings/list' element={<ListBuildings />} />
                  <Route path='/buildings/:id/details' element={<BuildingDetails />} />

                  <Route path='/trucks/new' element={<NewTruck />} />
                  <Route path='/trucks/:id/details' element={<TruckDetails />} />
                  <Route path='/trucks/list' element={<ListTrucks />} />

                  <Route path='/delivery_notes/new' element={<NewDeliveryNote />} />
                  <Route path='/delivery_notes/list' element={<ListDeliveryNotes />} />
                  <Route path='/delivery_notes/:id/details' element={<DeliveryNoteDetails />} />

                  <Route path='/providers/new' element={<NewProvider />} />
                  <Route path='/providers/:id/details' element={<ProviderDetails />} />
                  <Route path='/providers/list' element={<ListProviders />} />

                  <Route path='/trailers/new' element={<NewTrailer />} />
                  <Route path='/trailers/:id/details' element={<TrailerDetails />} />
                  <Route path='/trailers/list' element={<ListTrailers />} />

                  <Route path='/truck_drivers/new' element={<NewTruckDriver />} />
                  <Route path='/truck_drivers/:id/details' element={<TruckDriverDetails />} />
                  <Route path='/truck_drivers/list' element={<ListTruckDrivers />} />

                  <Route path='/orders/new' element={<NewOrder />} />
                  <Route path='/orders/:id/details' element={<OrderDetails />} />
                  <Route path='/orders/list' element={<ListOrders />} />

                  <Route path='/offers/new' element={<NewOffer />} />
                  <Route path='/offers/:id/details' element={<OfferDetails />} />
                  <Route path='/offers/list' element={<ListOffers />} />

                  <Route path='/provider_orders/new' element={<NewProviderOrder />} />
                  <Route path='/provider_orders/:id/details' element={<ProviderOrderDetails />} />
                  <Route path='/provider_orders/list' element={<ListProviderOrders />} />
                  <Route path='/provider_delivery_notes/:id/details' element={<DeliveryNoteProviderDetails />} />

                  <Route path='/invoices/list' element={<ListInvoices />} />
                  <Route path='/invoices/:id/details' element={<InvoiceDetails />} />
                  <Route path='/invoices_provider/:id/details' element={<InvoiceProviderDetails />} />

                  <Route path='/users/me' element={<UserEdit />} />

                </Route>
                <Route path='*' element={<Home />} />

              </Routes>
            </Box>
          </AuthProvider>
        </SnackbarProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
