import React, { useState, useEffect, useCallback } from "react";
import Paginate from '../../components/Paginate';
import { itemsPerPage } from '../../components/Utils';
import { Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Button,
    Grid,
    CircularProgress,
    Typography,
    Autocomplete
} from "@mui/material";

function ListTrailers() {
    const { api } = useAuth();
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(true);
    const [fetchReset, setFetchReset] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(null);

    const [trailers, setTrailers] = useState([]);

    const [clients, setClients] = useState([]);
    const [clientSelected, setClientSelected] = useState(null);

    const [code, setCode] = useState(null);
    const [truckLicensePlate, setTruckLicensePlate] = useState(null);
    const [trailerLicensePlate, setTrailerLicensePlate] = useState(null);

    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('ASC');

    const fetchTrailers = useCallback(async () => {
        try {
            setLoading(true);
            const params = {};
            params.limit = itemsPerPage;
            params.offset = (currentPage - 1) * itemsPerPage;

            if (!fetchReset) {
                if (code) params.code = code;
                if (truckLicensePlate) params.truck_license_plate = truckLicensePlate;
                if (trailerLicensePlate) params.trailer_license_plate = trailerLicensePlate;
                if (clientSelected) params.client_id = clientSelected.id;
                if (sortColumn) params.sort_by = sortColumn;
                if (sortDirection) params.sort_direction = sortDirection;
            } else {
                setFetchReset(false);
            }

            const result = await api().get("/trailers/", { params });

            setTrailers(result.data[0]);
            setTotalItems(result.data[1]);
            setLoading(false);
        } catch (error) {
            setError(prevError => ({ ...prevError, general: 'Error obteniendo remolques.' }));
        }
    }, [api, currentPage, fetchReset, code, clientSelected, truckLicensePlate, trailerLicensePlate, sortColumn, sortDirection]);

    const fetchClients = useCallback(async () => {
        let fetchClientError = null;

        try {
            const result = await api().get('/clients/');
            if (result.status < 300) {
                setClients(result.data[0]);
            } else {
                fetchClientError = `Error ${result.status}: ${result.data.detail}`;
            }
        } catch (error) {
            fetchClientError = 'Error obteniendo clientes.';
        } finally {
            setError(prevError => ({ ...prevError, general: fetchClientError }));
        }
    }, [api]);

    useEffect(() => {
        fetchTrailers();
    }, [currentPage, sortColumn, sortDirection, fetchReset]);

    useEffect(() => {
        fetchClients();
    }, []);

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
        } else {
            setSortColumn(column);
            setSortDirection('ASC');
        }
        setCurrentPage(1);
    };

    const handleFilters = (action) => {
        setCurrentPage(1);

        if (action === "reset") {
            setCode(null);
            setTrailerLicensePlate(null);
            setTruckLicensePlate(null);
            setClientSelected(null);
            setFetchReset(true);
        }

        else if (action === "apply") {
            fetchTrailers();
        }
    };

    const cellStyle = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '75px'
    };

    return (
        <div>
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={3} sm={2}>
                                    <TextField
                                        fullWidth
                                        label="Código"
                                        name="code"
                                        value={code || ""}
                                        onChange={(e) => setCode(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <TextField
                                        fullWidth
                                        label="Matrícula camión"
                                        name="truckLicensePlate"
                                        value={truckLicensePlate || ""}
                                        onChange={(e) => setTruckLicensePlate(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <TextField
                                        fullWidth
                                        label="Matrícula remolque"
                                        name="trailerLicensePlate"
                                        value={trailerLicensePlate || ""}
                                        onChange={(e) => setTrailerLicensePlate(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <Autocomplete
                                        options={clients}
                                        getOptionLabel={(option) => option.name?.toString()}
                                        value={clientSelected}
                                        onChange={(e, newValue) => setClientSelected(newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Cliente"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1} justifyContent="flex-end">
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={() => handleFilters("apply")}>
                                        Aplicar Filtros
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" color="secondary" onClick={() => handleFilters("reset")}>
                                        Resetear Filtros
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                    <br />
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('code')} style={{ cursor: 'pointer' }}>
                                                Código {sortColumn === 'code' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('license_plate')} style={{ cursor: 'pointer' }}>
                                                Matrícula {sortColumn === 'name' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('tare')} style={{ cursor: 'pointer' }}>
                                                Tara {sortColumn === 'tare' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('client_code')} style={{ cursor: 'pointer' }}>
                                                Cliente {sortColumn === 'client_code' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('truck_license_plate')} style={{ cursor: 'pointer' }}>
                                                Matrícula camión {sortColumn === 'truck_license_plate' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('truck_tare')} style={{ cursor: 'pointer' }}>
                                                Tara camión {sortColumn === 'truck_tare' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {trailers.map(item => (
                                    <TableRow key={item.id}>
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            <Link to={`/trailers/${item.id}/details`}>
                                                {item.code || '-'}
                                            </Link>
                                        </TableCell>
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            {item.license_plate || '-'}
                                        </TableCell>
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            {item.tare || '-'}
                                        </TableCell>
                                        <TableCell sx={cellStyle}>
                                            <Link to={`/clients/${item.id}/details`}>
                                                {item.client?.code || '-'}
                                            </Link>
                                        </TableCell>
                                        <TableCell sx={cellStyle}>
                                            {item.truck?.license_plate || '-'}
                                        </TableCell>
                                        <TableCell sx={cellStyle}>
                                            {item.truck?.tare || '-'}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
            <br />
            <br />
            <br />
            <Paginate
                totalItems={totalItems}
                onPageChange={setCurrentPage}
                currentPage={currentPage}
            />
            {error.general && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error.general}
                </Typography>
            )}
        </div>
    );
}

export default ListTrailers;