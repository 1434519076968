import React, { useState, useEffect } from "react";
import { styled, useTheme } from '@mui/material/styles';
import {
    Box,
    Drawer as MuiDrawer,
    AppBar as MuiAppBar,
    Toolbar,
    List,
    CssBaseline,
    Collapse,
    Typography,
    Divider,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import {
    Menu as MenuIcon,
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useAuth } from "../pages/login/OAuth";
import { FaTruck, FaVest, FaFileAlt, FaBuilding, FaBox, FaInfo, FaCoins, FaFileInvoice, FaTag } from 'react-icons/fa';
import { MdBusiness, MdSupervisorAccount, MdOutlineInventory2 } from 'react-icons/md';


const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const menuItems = [
    {
        name: 'Albaranes', icon: < FaFileAlt />, path: '/delivery_notes', subMenu: [
            { name: 'Nuevo albarán', path: '/delivery_notes/new', allowedRol: ["User", "admin"] },
            { name: 'Ver albaranes', path: '/delivery_notes/list', allowedRol: ["User", "admin"] },
        ]
    },
    {
        name: 'Pedidos venta', icon: < FaFileInvoice />, path: '/orders', subMenu: [
            { name: 'Nuevo pedido', path: '/orders/new', allowedRol: ["User", "admin"] },
            { name: 'Ver pedidos', path: '/orders/list', allowedRol: ["User", "admin"] },
        ]
    },
    {
        name: 'Ofertas', icon: < FaTag />, path: '/offers', subMenu: [
            { name: 'Nueva oferta', path: '/offers/new', allowedRol: ["User", "admin"] },
            { name: 'Ver ofertas', path: '/offers/list', allowedRol: ["User", "admin"] },
        ]
    },
    {
        name: 'Clientes', icon: <MdSupervisorAccount />, path: '/clients', subMenu: [
            { name: 'Nuevo cliente', path: '/clients/new', allowedRol: ["User", "admin"] },
            { name: 'Ver clientes', path: '/clients/list', allowedRol: ["User", "admin"] },
        ]
    },
    {
        name: 'Proveedores', icon: <MdBusiness />, path: '/providers', subMenu: [
            { name: 'Nuevo proveedor', path: '/providers/new', allowedRol: ["User", "admin"] },
            { name: 'Ver proveedores', path: '/providers/list', allowedRol: ["User", "admin"] },

        ]
    },
    {
        name: 'Pedidos proveedor', icon: <MdOutlineInventory2  />, path: '/provider_orders', subMenu: [
            { name: 'Nuevo pedido proveedor', path: '/provider_orders/new', allowedRol: ["User", "admin"] },
            { name: 'Ver pedidos ', path: '/provider_orders/list', allowedRol: ["User", "admin"] },

        ]
    },
    {
        name: 'Obras', icon: <FaBuilding />, path: '/buildings', subMenu: [
            { name: 'Nueva obra', path: '/buildings/new', allowedRol: ["User", "admin"] },
            { name: 'Ver obras', path: '/buildings/list', allowedRol: ["User", "admin"] }
        ]
    },
    {
        name: 'Productos', icon: <FaBox />, path: '/products', subMenu: [
            { name: 'Nuevo producto', path: '/products/new', allowedRol: ["User", "admin"] },
            { name: 'Ver productos', path: '/products/list', allowedRol: ["User", "admin"] },
            { name: 'Ver/crear canteras', path: '/quarries/list', allowedRol: ["User", "admin"] },
        ]
    },
    {
        name: 'Vehículos', icon: <FaTruck />, path: '/trucks', subMenu: [
            { name: 'Nuevo camión', path: '/trucks/new', allowedRol: ["User", "admin"] },
            { name: 'Nuevo remolque', path: '/trailers/new', allowedRol: ["User", "admin"] },
            { name: 'Ver camiones', path: '/trucks/list', allowedRol: ["User", "admin"] },
            { name: 'Ver remolques', path: '/trailers/list', allowedRol: ["User", "admin"] },
        ]
    },
    {
        name: 'Camioneros', icon: <FaVest />, path: '/truck_drivers', subMenu: [
            { name: 'Nuevo camionero', path: '/truck_drivers/new', allowedRol: ["User", "admin"] },
            { name: 'Ver camioneros', path: '/truck_drivers/list', allowedRol: ["User", "admin"] }
        ]
    },
    {
        name: 'Facturas', icon: <FaCoins />, path: '/invoices',  allowedRol: ["admin", 'superadmin'],
        subMenu: [
            { name: 'Ver facturas', path: '/invoices/list', allowedRol: ["admin", 'superadmin'] },
        ]
    }
];

export default function MiniDrawer() {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const { authenticated, logout, userRole } = useAuth()

    const [openSubMenu, setOpenSubMenu] = useState(null);

    const handleSubMenuClick = (path) => {
        setOpenSubMenu(openSubMenu === path ? null : path);
        handleDrawerOpen();
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        if (!authenticated) {
            handleDrawerClose();
        }
    }, [authenticated]);

    const handleDrawerClose = () => {
        setOpen(false);
        setOpenSubMenu(false)
    };

    const handleClick = (path) => {
        setOpenSubMenu(false)
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} sx={{ backgroundColor: '#E66136' }}>
                <Toolbar>
                    {authenticated && (

                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    <Typography variant="h6" noWrap component={Link} to="/" color="inherit" sx={{ flexGrow: 1, textDecoration: 'none' }}>
                        GRAHOR
                    </Typography>

                    {authenticated ? (
                        <Typography variant="body1" noWrap color="inherit" component={Link} to="/" onClick={logout} sx={{ textDecoration: 'none' }}>
                            Cerrar sesión
                        </Typography>

                    ) : (
                        <></>
                    )}
                </Toolbar>
            </AppBar>
            {authenticated && (

                <Drawer variant="permanent" open={open}>
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />

                    <List>
                        {menuItems.map((item) => (
                            <div key={item.name}>
                                <ListItem disablePadding sx={{ display: 'block' }}>
                                    {item.subMenu ? (
                                        <ListItemButton
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 3.5,
                                            }}
                                            onClick={() => handleSubMenuClick(item.path)}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
                                            {open ? <ExpandMoreIcon /> : <></>}
                                        </ListItemButton>
                                    ) : (
                                        <ListItemButton
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,
                                            }}
                                            component={Link}
                                            to={item.path}
                                            onClick={() => handleClick(item.name)}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
                                        </ListItemButton>
                                    )}
                                </ListItem>
                                {item.subMenu && (
                                    <Collapse in={openSubMenu === item.path} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {item.subMenu.map((subItem) => {
                                                const showItem = subItem.allowedRol.includes(userRole);
                                                return showItem && (
                                                    <ListItem key={subItem.name} disablePadding>
                                                        <ListItemButton
                                                            sx={{
                                                                minHeight: 48,
                                                                justifyContent: 'center',
                                                                px: 4,
                                                            }}
                                                            component={Link}
                                                            to={subItem.path}
                                                            onClick={() => handleClick(item.name)}
                                                        >
                                                            <ListItemText primary={subItem.name} />
                                                        </ListItemButton>
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </Collapse>
                                )}
                            </div>
                        ))}
                    </List>


                </Drawer>
            )}
        </Box>
    );
}