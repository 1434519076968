import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='/images/deliverynotes.jpg'
              text='Ver albaranes'
              label='Albarán'
              path='/delivery_notes/list'
            />
            <CardItem
              src='/images/deliverynoteG.jpg'
              text='Crear albarán'
              label='Albarán'
              path='/delivery_notes/new'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='/images/provider.jpg'
              text='Crear OP. transporte'
              label='OP. transporte'
              path='/providers/new'
            />
            <CardItem
              src='/images/client.png '
              text='Ver clientes'
              label='Cliente'
              path='/clients/list'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;