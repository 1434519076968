import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { Paper, Grid, Box, Typography, Button, CircularProgress, Divider, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { LocalShipping, Person, WorkOutline } from '@mui/icons-material';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import TerrainIcon from '@mui/icons-material/Terrain';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import { CreatePdf, CreateCsv, CreateInvoice } from '../../components/Utils';

function DeliveryNoteDetails() {
    const { id } = useParams();
    const [deliveryNote, setDeliveryNote] = useState(null);
    const [loading, setLoading] = useState(true);
    const { api } = useAuth();
    const [error, setError] = useState(null);

    const fetchDeliveryNote = async () => {
        try {
            setLoading(true);
            const response = await api().get(`/delivery_notes/${id}`);
            setDeliveryNote(response.data);
            setLoading(false);
        } catch (error) {
            setError('Error obteniendo albarán');
        }
    };


    useEffect(() => {
        fetchDeliveryNote();
    }, []);

    return (
        <div style={{ margin: '20px' }}>
            <Typography variant="h4" gutterBottom align="center">Detalles del albarán</Typography>
            <Divider style={{ marginBottom: '20px' }} />
            {loading ? (
                <CircularProgress />
            ) : (
                <Paper elevation={3} style={{ padding: '20px' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1">Nº de albarán: {deliveryNote.code}</Typography>
                            <Typography variant="subtitle1">Fecha de salida: {new Date(deliveryNote.departure_date).toLocaleString()}</Typography>
                            <Typography variant="subtitle1">Bruto: {deliveryNote.gross} kg</Typography>
                            <Typography variant="subtitle1">Tara: {deliveryNote.gross - deliveryNote.net} kg</Typography>
                            <Typography variant="subtitle1">Neto: {deliveryNote.net} kg</Typography>
                            {deliveryNote.observations && <Typography variant="subtitle1">Observaciones: {deliveryNote.observations}</Typography>}
                            {/* Botones para generar PDF y CSV usando CreatePdf y CreateCsv */}
                            <Box display="flex" flexDirection="column">
                                <CreatePdf
                                    endpoint={`/delivery_notes/${id}/generate-pdf`}
                                    text="Generar PDF"
                                    sx={{ maxWidth: '120px', marginBottom: '10px' }}
                                />
                                <CreateCsv
                                    endpoint={`/delivery_notes/${id}/generate-csv`}
                                    name={`albaran_${deliveryNote.code}`}
                                    text="Generar CSV"
                                    sx={{ maxWidth: '120px', marginBottom: '10px' }}
                                />
                                {deliveryNote.invoices && deliveryNote.invoices.length > 0 ? (
                                    // Mostrar detalles de la factura
                                    <Box mt={2}>
                                        <Typography variant="h6" gutterBottom>Detalles de la Factura</Typography>
                                        <Typography variant="body1"><strong>Código:</strong>
                                            <Link to={`/invoices/${deliveryNote.invoices[0].id}/details`}>
                                                {deliveryNote.invoices[0].code}
                                            </Link>
                                        </Typography>
                                        <Typography variant="body1"><strong>Fecha de Factura:</strong> {new Date(deliveryNote.invoices[0].invoice_date).toLocaleDateString()}</Typography>
                                        <Typography variant="body1"><strong>Precio Base:</strong> {deliveryNote.invoices[0].base_price} €</Typography>
                                        <Typography variant="body1"><strong>IVA:</strong> {deliveryNote.invoices[0].vat}%</Typography>
                                        <Typography variant="body1"><strong>Precio Total:</strong> {deliveryNote.invoices[0].total_price} €</Typography>
                                        <Typography variant="body1"><strong>Monto Pagado:</strong> {deliveryNote.invoices[0].paid_amount} €</Typography>
                                    </Box>
                                ) : (
                                    <CreateInvoice
                                        isProviderInvoice={false}
                                        deliveryNotes={[id]}
                                        fetch={fetchDeliveryNote}
                                        sx={{ maxWidth: '120px' }}
                                    />
                                )}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h6" gutterBottom>Información</Typography>
                            <List>
                                {deliveryNote.truck_driver && (
                                    <ListItem>
                                        <ListItemIcon><DriveEtaIcon /></ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <>
                                                    Transportista:
                                                    <Link to={`/truck_drivers/${deliveryNote.truck_driver.id}/details`}>
                                                        {` ${deliveryNote.truck_driver.name}`}
                                                    </Link>
                                                </>
                                            }
                                        />
                                    </ListItem>
                                )}
                                {deliveryNote.truck && (
                                    <ListItem>
                                        <ListItemIcon><LocalShipping /></ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <>
                                                    Camión:
                                                    <Link to={`/trucks/${deliveryNote.truck.id}/details`}>
                                                        {` ${deliveryNote.truck.license_plate}`}
                                                    </Link>
                                                </>
                                            }
                                        />
                                    </ListItem>
                                )}
                                {deliveryNote.trailer && (
                                    <ListItem>
                                        <ListItemIcon><RvHookupIcon /></ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <>
                                                    Remolque:
                                                    <Link to={`/trailers/${deliveryNote.trailer.id}/details`}>
                                                        {` ${deliveryNote.trailer.license_plate}`}
                                                    </Link>
                                                </>
                                            }
                                        />
                                    </ListItem>
                                )}
                                {deliveryNote.truck.client && (
                                    <ListItem>
                                        <ListItemIcon><Person /></ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <>
                                                    Cliente:
                                                    <Link to={`/clients/${deliveryNote.truck.client.id}/details`}>
                                                        {` ${deliveryNote.truck.client.name}`}
                                                    </Link>
                                                </>
                                            }
                                        />
                                    </ListItem>
                                )}
                                {deliveryNote.building && (
                                    <ListItem>
                                        <ListItemIcon><WorkOutline /></ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <>
                                                    Obra:
                                                    <Link to={`/buildings/${deliveryNote.building.id}/details`}>
                                                        {` ${deliveryNote.building.name}`}
                                                    </Link>
                                                </>
                                            }
                                        />
                                    </ListItem>
                                )}
                                {deliveryNote.product.quarry && (
                                    <ListItem>
                                        <ListItemIcon><TerrainIcon /></ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <>
                                                    Cantera:
                                                    <Link to={`/quarries/${deliveryNote.product.quarry.id}/details`}>
                                                        {` ${deliveryNote.product.quarry.name}`}
                                                    </Link>
                                                </>
                                            }
                                        />
                                    </ListItem>
                                )}
                                <Divider />
                                <br />

                                {/* Mostrar un único producto y su información */}
                                <Typography variant="h6" gutterBottom>
                                    {deliveryNote.order_detail ? 'Detalles del Pedido' : 'Producto'}
                                </Typography>
                                <ListItem>
                                    {deliveryNote.order_detail ? (
                                        <Box display="flex" flexDirection="column">
                                            <Typography variant="body1">
                                                Código:
                                                <Link to={`/orders/${deliveryNote.order_detail.order.id}/details`}>
                                                    {` ${deliveryNote.order_detail.order.code}`}
                                                </Link>
                                            </Typography>
                                            <Typography variant="body1">
                                                Producto: {deliveryNote.product.product_group.name}
                                            </Typography>
                                            <Typography variant="body1">
                                                Cantidad: {deliveryNote.net} {deliveryNote.product.product_group.unit}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Box display="flex" flexDirection="column">
                                            <Typography variant="body1">
                                                Nombre: {deliveryNote.product.product_group.name}
                                            </Typography>
                                            <Typography variant="body1">
                                                Cantidad: {deliveryNote.net}
                                            </Typography>
                                        </Box>
                                    )}
                                </ListItem>
                            </List>
                        </Grid>

                    </Grid>
                    {error && (
                        <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                            {error}
                        </Typography>
                    )}
                </Paper>

            )}
        </div>
    );
}

export default DeliveryNoteDetails;
