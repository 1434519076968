import React from "react";
import { Button } from "@mui/material";
import { itemsPerPage as defaultItemsPerPage} from '../components/Utils';

function Paginate({ totalItems, itemsPerPage, onPageChange, currentPage }) { 

    const itemsPerPageToUse = itemsPerPage || defaultItemsPerPage;
    const totalPages = Math.ceil(totalItems / itemsPerPageToUse);

    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    return (
        <div style={{ textAlign: 'center' }}>
            <Button onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1}>Anterior</Button>
            {pageNumbers.map((page) =>
                Math.abs(currentPage - page) <= 3 && (
                    <Button
                        key={page}
                        onClick={() => onPageChange(page)}
                        style={{
                            backgroundColor: currentPage === page ? 'rgb(67, 120, 182)' : '', // Color azul para la página actual
                            color: currentPage === page ? 'white' : 'black', // Texto blanco para la página actual
                            borderRadius: '50%',
                            width: '30px',
                            height: '30px',
                            textAlign: 'center',
                            lineHeight: '30px',
                            padding: 0,
                            display: 'inline-block',
                            margin: '0 5px',
                            fontSize: currentPage === page ? '1em' : '0.85em', // Texto más grande para la página actual
                        }}
                    >
                        {page}
                    </Button>
                )
            )}
            <Button onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages}>Siguiente</Button>
        </div>
    );
}

export default Paginate;