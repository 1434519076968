import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../login/OAuth';
import { useSnackbar } from '../../components/SnackbarProvider';
import { DecimalTextField, handleApiError } from '../../components/Utils';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';
import {
    Button,
    TextField,
    Grid,
    Box,
    Paper,
    Typography,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    CircularProgress,
    Autocomplete,
    FormHelperText,
} from "@mui/material";

dayjs.extend(utc);

function NewTrailer() {

    const { api } = useAuth();
    const navigate = useNavigate();
    const showSnackbar = useSnackbar();

    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);

    const [trucks, setTrucks] = useState([]);
    const [truckSelected, setTruckSelected] = useState(null);

    const [trailerForm, setTrailerForm] = useState({
        license_plate: null,
        tare: null,
        truck_id: null,
        trailer_type: null,
    });

    const handleChangeForm = (event) => {
        const { name, value } = event.target;
        setTrailerForm(prevState => ({
            ...prevState,
            [name]: value
        }));
        setError(prevError => ({ ...prevError, [name]: null }));
    };

    const handleTruckChange = (event, newValue) => {
        setTruckSelected(newValue);
        setTrailerForm(prevState => ({
            ...prevState,
            truck_id: newValue?.id
        }));
        setError(prevError => ({ ...prevError, truck_id: null }));
    };

    useEffect(() => {
        fetchTrucks();
    }, []);

    const fetchTrucks = useCallback(async () => {
        let fetchTruckError = null;

        try {
            const result = await api().get('/trucks/');
            if (result.status < 300) {
                setTrucks(result.data[0]);
            } else {
                fetchTruckError = `Error ${result.status}: ${result.data.detail}`;
            }
        } catch (error) {
            fetchTruckError = 'Error obteniendo camiones.';
        } finally {
            setError(prevError => ({ ...prevError, general: fetchTruckError }));
        }
    }, [api]);


    const validateForm = () => {
        const errors = {};

        if (!trailerForm.license_plate) {
            errors.license_plate = "Por favor, añada una matrícula.";
        } else if (trailerForm.license_plate.length > 10) {
            errors.license_plate = "La matrícula no debe exceder los 10 caracteres.";
        }
        if (!trailerForm.tare) {
            errors.tare = "Por favor, añada una tara.";
        }
        if (!trailerForm.trailer_type) {
            errors.trailer_type = "Por favor, seleccione un tipo de trailer.";
        }

        return Object.keys(errors).length > 0 ? errors : null;
    };


    const createTrailer = async (event) => {

        let error = null;

        try {
            event.preventDefault();
            const validationErrors = validateForm();
            if (validationErrors) {
                setError(validationErrors);
                return;
            }

            setLoading(true);

            await api().post('/trailers/', trailerForm);

            showSnackbar("Remolque creado correctamente");
            navigate(`/trailers/list`);
            setError(null);

        } catch (e) {
            error = handleApiError(e, 'Error inesperado creando remolque.')
        } finally {
            setError(prevError => ({ ...prevError, general: error }));
            setLoading(false);
        }
    };


    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '120%' }}>
            <Paper elevation={3} style={{ padding: 20, width: '40%' }}>
                <Typography component="h2" variant="h5">
                    Nuevo Remolque
                </Typography>
                <br />
                <form onSubmit={createTrailer}>
                    <Grid container spacing={2}>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="Matrícula"
                                name="license_plate"
                                value={trailerForm.license_plate}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 10 }}
                                error={!!error?.license_plate}
                                helperText={error?.license_plate}
                            />
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <DecimalTextField
                                label="Tara (Kg)"
                                name="tare"
                                value={trailerForm.tare}
                                onChange={handleChangeForm}
                                error={!!error?.tare}
                                helperText={error?.tare}
                            />
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <FormControl
                                fullWidth
                                error={!!error?.trailer_type}
                            >
                                <InputLabel id="trailer-type-label">
                                    Tipo de Remolque
                                </InputLabel>
                                <Select
                                    labelId="trailer-type-label"
                                    id="trailer-type"
                                    label='Tipo Trailer'
                                    value={trailerForm.trailer_type}
                                    onChange={handleChangeForm}
                                    name="trailer_type"
                                    inputProps={{ id: 'trailer-type' }}
                                >
                                    <MenuItem value="Carrillero_3_ejes_rigidos">CARRILLERO 3 EJES RÍGIDOS</MenuItem>
                                    <MenuItem value="Bañera_2_ejes">BAÑERA 2 EJES</MenuItem>
                                    <MenuItem value="Bañera_3_ejes">BAÑERA 3 EJES</MenuItem>
                                    <MenuItem value="Camion_15_tn">CAMION_15_TN</MenuItem>
                                </Select>
                                {error?.trailer_type && (
                                    <FormHelperText>{error.trailer_type}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <Autocomplete
                                options={trucks}
                                getOptionLabel={(option) => option.license_plate?.toString()}
                                value={truckSelected}
                                onChange={handleTruckChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Camión (opcional)"
                                        fullWidth
                                        error={!!error?.truck_id}
                                        helperText={error?.truck_id}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <Button type="submit" variant="contained" color="primary" disabled={loading}>
                                {loading ? <CircularProgress size={24} /> : 'Crear'}
                            </Button>
                        </Box>
                    </Grid>
                </form>
                {error?.general && (
                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                        {error.general}
                    </Typography>
                )}
            </Paper >
        </div >
    );
}

export default NewTrailer;
