import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import {
    CircularProgress,
    Typography,
    Button,
    Card,
    CardContent,
    CardHeader,
    TableCell,
    TableRow,
    TableHead,
    TableBody,
    Grid,
    TableContainer,
    Table,
    Paper,
    Alert,
} from '@mui/material';
import {
    formatDate,
    formatPrice,
    formatTime,
    formatOrderStatus,
    CreatePdf,
    DisableDialog
} from '../../components/Utils';


function OrderDetails() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const { api } = useAuth();
    const [error, setError] = useState(null);
    const [order, setOrder] = useState(null);
    const [statusUpdateError, setStatusUpdateError] = useState(null);

    const [openDisableDialog, setOpenDisableDialog] = useState(false);
    const [disableId, setDisableId] = useState(null);
    const [navigateToWhenDisabled, setNavigateToWhenDisabled] = useState(false);
    const [endpointToDisable, setEndpointToDisable] = useState(false);

    const fetchOrder = useCallback(async () => {
        try {
            const result = await api().get(`/orders/${id}`);
            setOrder(result.data);
            setLoading(false);
        } catch (error) {
            setError("Error obteniendo albarán");
            setLoading(false);
        }
    }, [api, id]);

    useEffect(() => {
        fetchOrder();
    }, []);

    const handleOpenDisableDialog = (itemId, endpoint, navigateTo) => {
        setDisableId(itemId);
        setEndpointToDisable(endpoint);
        setNavigateToWhenDisabled(navigateTo);
        setOpenDisableDialog(true);
    };

    const total = order ? order.order_details.reduce((acc, detail) => acc + detail.total_price, 0) : 0;

    return (
        <div style={{ padding: 20 }}>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            ) : (
                <div>
                    {/* Mostrar el mensaje de error si existe */}
                    {error && (
                        <Alert severity="error" style={{ marginBottom: '20px' }}>
                            {error}
                        </Alert>
                    )}
                    {/* Mostrar el mensaje de error al marcar como recibido */}
                    {statusUpdateError && (
                        <Alert severity="error" style={{ marginBottom: '20px' }}>
                            {statusUpdateError}
                        </Alert>
                    )}

                    <Card sx={{ margin: 'auto' }}>
                        <CardHeader
                            title={(
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', padding: '20px 0' }}>
                                    <span style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                                        {`Pedido: ${order.code} | Fecha: ${formatDate(order.order_date) || '-'} ${formatTime(order.order_date) || '-'}`}
                                    </span>

                                    <Grid item xs={2}
                                        style={{
                                            position: 'absolute',
                                            right: '16px',
                                        }}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleOpenDisableDialog(id, '/orders/disable', '/orders/list')}
                                            style={{ backgroundColor: 'red', marginRight: '16px' }} // Margen derecho para separación
                                        >
                                            Eliminar
                                        </Button>
                                    </Grid>
                                </div>
                            )}
                            sx={{
                                backgroundColor: '#f5f5f5',
                                textAlign: 'center',
                                fontWeight: 'bold',
                                padding: '16px 0'
                            }}
                        />
                        {/* Diálogo de confirmación de eliminación */}
                        <DisableDialog
                            open={openDisableDialog}
                            onClose={() => setOpenDisableDialog(false)}
                            endpoint={endpointToDisable}
                            disableId={disableId}
                            navigateTo={navigateToWhenDisabled}
                        />
                        <CardContent>
                            <Grid container spacing={3}>
                                {/* Información del Cliente */}
                                <Grid item xs={12} md={6}>
                                    <Paper elevation={3} sx={{ padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '8px', marginBottom: '20px', textAlign: 'left' }}>
                                        <Typography variant="h6" sx={{ mb: 3, fontWeight: 'bold', fontSize: '16px' }}>
                                            Información del Cliente
                                        </Typography>
                                        <Typography variant="body2" sx={{ fontSize: '14px', marginBottom: '10px' }}>
                                            <strong>Código: </strong>
                                            <Link to={`/clients/${order.client_id}/details`}>
                                                {order.client.code || '-'} <br />
                                            </Link>
                                            <strong>Nombre: </strong> {order.client.name || '-'} <br />
                                            <strong>Email: </strong> {order.client.email || '-'} <br />
                                            <strong>Teléfono: </strong> {order.client.telephone_number || '-'} <br />
                                            <strong>CIF: </strong> {order.client.cif || '-'} <br />
                                        </Typography>
                                    </Paper>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Paper elevation={3} sx={{ padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '8px', marginBottom: '20px' }}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="h6" sx={{ mb: 3, fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>
                                                    Estado del Pedido
                                                </Typography>
                                                <Typography variant="body2" sx={{ fontSize: '14px', marginBottom: '10px', textAlign: 'center' }}>
                                                    {formatOrderStatus(order.order_status) || '-'}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <Typography variant="h6" sx={{ mb: 3, fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>
                                                    Generar PDF
                                                </Typography>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <CreatePdf endpoint={`/orders/${id}/generate-pdf`}>
                                                        <Button variant="outlined" color="secondary" sx={{ width: '100%' }}>
                                                            Crear PDF
                                                        </Button>
                                                    </CreatePdf>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>

                            {/* Tabla de productos del Pedido */}
                            <Paper elevation={3} sx={{ padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '8px', marginTop: '20px' }}>
                                <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>
                                    Pedido: {order.code || '-'}
                                </Typography>

                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><strong>Producto</strong></TableCell>
                                                <TableCell><strong>Cantera</strong></TableCell>
                                                <TableCell><strong>Estado</strong></TableCell>
                                                <TableCell><strong>Cantidad</strong></TableCell>
                                                <TableCell><strong>Cantidad recibida</strong></TableCell>
                                                <TableCell><strong>Precio Unitario</strong></TableCell>
                                                <TableCell><strong>Total</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {order.order_details.map((detail) => (
                                                <TableRow key={detail.id}>
                                                    <TableCell>{detail.product_name || '-'}</TableCell>
                                                    <TableCell>{detail.product.quarry.name || '-'}</TableCell>
                                                    <TableCell>{formatOrderStatus(detail.status) || '-'}</TableCell>
                                                    <TableCell>{detail.quantity} {detail.unit}</TableCell>
                                                    <TableCell>{detail.delivered_quantity} {detail.unit}</TableCell>
                                                    <TableCell>{formatPrice(detail.unit_price)}</TableCell>
                                                    <TableCell>{formatPrice(detail.total_price)}</TableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow>
                                                <TableCell rowSpan={6} colSpan={5}></TableCell>
                                                <TableCell><strong>Total</strong></TableCell>
                                                <TableCell><strong>{formatPrice(total)}</strong></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </CardContent>
                    </Card>
                </div>
            )}
        </div>
    );
}

export default OrderDetails;
