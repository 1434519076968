import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../login/OAuth';
import { useSnackbar } from '../../components/SnackbarProvider';
import { DecimalTextField, DatePickerComponent, handleApiError } from '../../components/Utils';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';
import {
    Button,
    TextField,
    Grid,
    Box,
    Paper,
    Typography,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    CircularProgress,
    Autocomplete,
    FormHelperText,
} from "@mui/material";

dayjs.extend(utc);

function NewTruck() {

    const { api } = useAuth();
    const navigate = useNavigate();
    const showSnackbar = useSnackbar();

    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);

    const [clients, setClients] = useState([]);
    const [clientSelected, setClientSelected] = useState(null);

    const [truckForm, setTruckForm] = useState({
        license_plate: null,
        tare: null,
        client_id: null,
        expiration_date: null,
        trailer_type: null,
        trailer_license_plate: null,
        trailer_tare: null,
    });

    const handleChangeForm = (event) => {
        const { name, value } = event.target;
        setTruckForm(prevState => ({
            ...prevState,
            [name]: value
        }));
        if (name === 'trailer_type' && value==='Ninguno'){
            setTruckForm(prevState => ({
                ...prevState,
                trailer_license_plate: null
            }));
            setTruckForm(prevState => ({
                ...prevState,
                trailer_tare: null
            }));
            
        }
        setError(prevError => ({ ...prevError, [name]: null }));
    };

    const handleDateChange = (name, newValue) => {
        let date = dayjs(newValue).hour(12).minute(0).second(0);
        date = date.utc().format('YYYY-MM-DD');
        setTruckForm(prevState => ({
            ...prevState,
            [name]: date
        }));
        setError(prevError => ({
            ...prevError,
            [name]: ''
        }));
    };

    const handleClientChange = (event, newValue) => {
        setClientSelected(newValue);
        setTruckForm(prevState => ({
            ...prevState,
            client_id: newValue?.id
        }));
        setError(prevError => ({ ...prevError, client_id: null }));
    };

    useEffect(() => {
        fetchClients();
    }, []);

    const fetchClients = useCallback(async () => {
        let fetchClientError = null;

        try {
            const result = await api().get('/clients/');
            if (result.status < 300) {
                setClients(result.data[0]);
            } else {
                fetchClientError = `Error ${result.status}: ${result.data.detail}`;
            }
        } catch (error) {
            fetchClientError = 'Error obteniendo clientes.';
        } finally {
            setError(prevError => ({ ...prevError, general: fetchClientError }));
        }
    }, [api]);


    const validateForm = () => {
        const errors = {};

        if (!truckForm.license_plate) {
            errors.license_plate = "Por favor, añada una matrícula.";
        } else if (truckForm.license_plate.length > 10) {
            errors.license_plate = "La matrícula no debe exceder los 10 caracteres.";
        }

        if (!truckForm.tare) {
            errors.tare = "Por favor, añada una tara.";
        }

        if (!clientSelected) {
            errors.client_id = "Por favor, seleccione un cliente.";
        }

        if (!truckForm.trailer_type) {
            errors.trailer_type = "Por favor, seleccione un tipo de trailer.";
        } else if (truckForm.trailer_type !== "Ninguno") {
            if (!truckForm.trailer_license_plate) {
                errors.trailer_license_plate = "Por favor, añada una matrícula para el trailer.";
            } else if (truckForm.trailer_license_plate.length > 10) {
                errors.trailer_license_plate = "La matrícula del trailer no debe exceder los 10 caracteres.";
            }

            if (!truckForm.trailer_tare) {
                errors.trailer_tare = "Por favor, añada una tara para el trailer.";
            }
        }

        return Object.keys(errors).length > 0 ? errors : null;
    };


    const createTruck = async (event) => {

        let error = null;

        try {
            event.preventDefault();
            const validationErrors = validateForm();
            if (validationErrors) {
                setError(validationErrors);
                return;
            }

            setLoading(true);

            await api().post('/trucks/', truckForm);

            showSnackbar("Camión creado correctamente");
            navigate(`/trucks/list`);
            setError(null);

        } catch (e) {
            error = handleApiError(e, 'Error inesperado creando camión.')
        } finally {
            setError(prevError => ({ ...prevError, general: error }));
            setLoading(false);
        }
    };


    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '120%' }}>
            <Paper elevation={3} style={{ padding: 20, width: '40%' }}>
                <Typography component="h2" variant="h5">
                    Nuevo Camión
                </Typography>
                <br />
                <form onSubmit={createTruck}>
                    <Grid container spacing={2}>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="Matrícula"
                                name="license_plate"
                                value={truckForm.license_plate}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 10 }}
                                error={!!error?.license_plate}
                                helperText={error?.license_plate}
                            />
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <DecimalTextField
                                label="Tara (Kg)"
                                name="tare"
                                value={truckForm.tare}
                                onChange={handleChangeForm}
                                error={!!error?.tare}
                                helperText={error?.tare}
                            />
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <Autocomplete
                                options={clients}
                                getOptionLabel={(option) => option.name?.toString()}
                                value={clientSelected}
                                onChange={handleClientChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Client"
                                        fullWidth
                                        error={!!error?.client_id}
                                        helperText={error?.client_id}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <DatePickerComponent
                                label="Fecha expiración"
                                value={truckForm.expiration_date}
                                onChange={(newValue) => handleDateChange('expiration_date', newValue)}
                            />
                        </Grid>
                        <Grid item xs={10} md={12}>
                            <FormControl
                                fullWidth
                                error={!!error?.trailer_type}
                            >
                                <InputLabel id="trailer-type-label">
                                    Tipo de Remolque
                                </InputLabel>
                                <Select
                                    labelId="trailer-type-label"
                                    id="trailer-type"
                                    label='Tipo Trailer'
                                    value={truckForm.trailer_type}
                                    onChange={handleChangeForm}
                                    name="trailer_type"
                                    inputProps={{ id: 'trailer-type' }}
                                >
                                    <MenuItem value="Carrillero_3_ejes_rigidos">CARRILLERO 3 EJES RÍGIDOS</MenuItem>
                                    <MenuItem value="Bañera_2_ejes">BAÑERA 2 EJES</MenuItem>
                                    <MenuItem value="Bañera_3_ejes">BAÑERA 3 EJES</MenuItem>
                                    <MenuItem value="Camion_15_tn">CAMION_15_TN</MenuItem>
                                    <MenuItem value="Ninguno">NINGUNO</MenuItem>
                                </Select>
                                {error?.trailer_type && (
                                    <FormHelperText>{error.trailer_type}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        {truckForm.trailer_type && truckForm.trailer_type !== "Ninguno" && (
                            <>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Matrícula del trailer"
                                        name="trailer_license_plate"
                                        value={truckForm.trailer_license_plate}
                                        onChange={handleChangeForm}
                                        inputProps={{ maxLength: 10 }}
                                        error={!!error?.trailer_license_plate}
                                        helperText={error?.trailer_license_plate}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DecimalTextField
                                        label="Tara del trailer"
                                        name="trailer_tare"
                                        value={truckForm.trailer_tare}
                                        onChange={handleChangeForm}
                                        error={!!error?.trailer_tare}
                                        helperText={error?.trailer_tare}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <Button type="submit" variant="contained" color="primary" disabled={loading}>
                                {loading ? <CircularProgress size={24} /> : 'Crear'}
                            </Button>
                        </Box>
                    </Grid>
                </form>
                {error?.general && (
                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                        {error.general}
                    </Typography>
                )}
            </Paper >
        </div >
    );
}

export default NewTruck;
