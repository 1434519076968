
import React, { useState } from 'react';
import { useAuth } from "../pages/login/OAuth";
import { useNavigate } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';
import { useSnackbar } from '../components/SnackbarProvider';
import AddIcon from '@mui/icons-material/Add';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    CircularProgress,
    TextField,
    DialogContent,
    Typography,
    Modal,
    Box
} from '@mui/material';

dayjs.extend(utc);

export const formatDate = (date) => {
    if (!date) {
        return '';
    }
    return dayjs(date).format('DD/MM/YYYY');
};

export const formatTime = (time) => {
    if (!time) {
        return '';
    }
    return dayjs(time).format('HH:mm'); // Formato de 24 horas (ej. 14:30)
};

export const formatDayjs = (datetime) => {
    if (!datetime) {
        return '';
    }
    return dayjs(datetime).hour(12).minute(0).second(0).utc().format('YYYY-MM-DD');
}

export const formatPrice = (price) => {
    return price ? price.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€' : '0,00€';
};

export const formatNumber = (number) => {
    return number ? number.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0';
};

export const formatAssignmentStatus = (status) => {
    switch (status) {
        case "En uso":
            return "ASOCIADO";
        case "Liberado":
            return "LIBERADO";
        case "Asociado":
            return "ASOCIADO";
        default:
            return "DESCONOCIDO";
    }
};


export const formatUnit = (status) => {
    switch (status) {
        case "kg":
            return "Kg";
        case "Tn":
            return "Tn";
        case "uds":
            return "Uds";
        case "l":
            return "L";
        default:
            return "DESCONOCIDO";
    }
};

export const formatOrderStatus = (status) => {
    switch (status) {
        case "partly_delivered":
            return "PARCIALMENTE ENTREGADO";
        case "delivered":
            return "ENTREGADO";
        case "pending":
            return "PENDIENTE";
        default:
            return "DESCONOCIDO";
    }
};

export const formatProviderOrderStatus = (status) => {
    switch (status) {
        case "partly_received":
                return "PARCIALMENTE RECIBIDO";
        case "received":
            return "RECIBIDO";
        case "pending":
            return "PENDIENTE";
        default:
            return "DESCONOCIDO";
    }
};

export const formatOfferStatus = (status) => {
    switch (status) {
        case "accepted":
            return "ACEPTADA";
        case "declined":
            return "RECHAZADA";
        case "pending":
            return "PENDIENTE";
        default:
            return "DESCONOCIDO";
    }
};

export const formatInvoiceStatus = (status) => {
    switch (status) {
        case "paid":
            return "PAGADA";
        case "not_paid":
            return "NO PAGADA";
        default:
            return "DESCONOCIDO";
    }
};

export const formatTrailerType = (trailerType) => {
    switch (trailerType) {
        case "Carrillero_3_ejes_rigidos":
            return "Carrillero 3 ejes rígidos";
        case "Bañera_2_ejes":
            return "Bañera 2 ejes";
        case "Bañera_3_ejes":
            return "Bañera 3 ejes";
        case "Camion_15_tn":
            return "Camión 15 toneladas";
        default:
            return "Ninguno";
    }
};

export const itemsPerPage = 10

export const DecimalTextField = ({
    label,
    name,
    value,
    onChange,
    fullWidth = true,
    required = false,
    error = false,
    helperText = '',
    disabled = false,
    ...props
}) => {

    const handleChange = (e) => {
        let { value } = e.target;

        // Cambiar coma por punto internamente para manejar los decimales
        value = value.replace(',', '.');

        // Verificar si el valor es un número válido con hasta 2 decimales
        const decimalPattern = /^\d+(\.\d{0,2})?$/;

        if ((decimalPattern.test(value) || value === '') && value <= 99000000) {
            onChange(e);  // Solo permite el valor si coincide con el patrón o si está vacío
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'e' || e.key === 'E' || e.key === ',') {
            e.preventDefault();  // Evita la entrada de exponente 'e' o 'E'
        }
    };

    return (
        <TextField
            label={label}
            name={name}
            type="text"
            value={value || ""}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            fullWidth={fullWidth}
            required={required}
            error={error}
            helperText={helperText}
            disabled={disabled}
            inputProps={{ inputMode: 'decimal', pattern: '^\\d+(\\.\\d{0,2})?$' }}
            {...props}
        />
    );
};

export const IntegerTextField = ({
    label,
    name,
    value,
    onChange,
    fullWidth = true,
    required = false,
    error = false,
    helperText = '',
    disabled = false,
    ...props
}) => {

    const handleChange = (e) => {
        const { value } = e.target;
        if (!isNaN(value) && Number.isInteger(Number(value)) && value <= 99000000) {
            onChange(e);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === '.' || e.key === ',' || e.key === 'e' || e.key === 'E') {
            e.preventDefault();
        }
    };

    return (
        <TextField
            label={label}
            name={name}
            type="number"
            value={value || ""}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            fullWidth={fullWidth}
            required={required}
            error={error}
            helperText={helperText}
            disabled={disabled}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            {...props}
        />
    );
};

export const DatePickerComponent = ({ label, value, onChange }) => {
    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="es"
            localeText={{
                cancelButtonLabel: "cancelar",
                okButtonLabel: "Ok",
                datePickerToolbarTitle: 'Seleccionar',
            }}
        >
            <MobileDatePicker
                label={label}
                closeOnSelect={true}
                value={value ? dayjs(value) : null}
                onChange={onChange}
                renderInput={(params) => <TextField {...params} fullWidth />}
            />
        </LocalizationProvider>
    );
};

export function handleApiError(error, defaultErrorMessage) {
    let errorMessage = defaultErrorMessage;

    if (error.response) {
        const { status, data } = error.response;
        errorMessage = `Error ${status}: ${data.detail || 'Error desconocido.'}`;
    }

    return errorMessage;
}

export const DisableDialog = ({ open, onClose, endpoint, disableId, navigateTo, fetch }) => {
    const { api } = useAuth();
    const navigate = useNavigate();
    const showSnackbar = useSnackbar();
    const [loading, setLoading] = useState(false);

    const handleDisableItem = async () => {
        setLoading(true);
        let error = null;
        try {

            await api().patch(`${endpoint}`, { id: disableId });

            showSnackbar('Eliminado correctamente');
            if (navigateTo) {
                navigate(navigateTo);
            } else {
                fetch();
            }
        } catch (e) {
            console.log(e)
            error = handleApiError(e, 'Error inesperado borrando objeto.')
        } finally {
            if (error) showSnackbar(error, 'error');
            onClose();
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>¿Estás seguro?</DialogTitle>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Volver
                </Button>
                <Button onClick={handleDisableItem} color="error">
                    {loading ? <CircularProgress size={24} color="inherit" /> : 'Eliminar'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const Accordion = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(true);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <button onClick={toggleAccordion} style={styles.accordionButton}>
                {title} {isOpen ? '-' : '+'}
            </button>
            {isOpen && <div style={styles.accordionContent}>{children}</div>}
        </div>
    );
};

export const CreatePdf = ({
    endpoint,
    sx = {},
    text = 'Mostrar pdf',
}) => {
    const { api } = useAuth();
    const showSnackbar = useSnackbar();

    const [loading, setLoading] = useState(false);

    const showPdf = async () => {
        let error = null;
        try {
            setLoading(true);
            const response = await api().get(`${endpoint}`, { responseType: 'blob' });
            const pdfUrl = URL.createObjectURL(response.data);
            window.open(pdfUrl, '_blank');

        } catch (e) {
            error = handleApiError(e, 'Error inesperado creando pdf.')
        } finally {
            if (error) showSnackbar(error, 'error');
            setLoading(false);
        }
    };

    return (
        <Button
            variant="contained"
            sx={{ backgroundColor: 'green', color: 'white', ...sx }}
            onClick={showPdf}
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
        >
            {loading ? '' : text}
        </Button>
    );
};

export const CreateCsv = ({
    endpoint,
    name,
    text = 'Generar csv',
    sx = {},
}) => {
    const { api } = useAuth();
    const showSnackbar = useSnackbar();

    const [loading, setLoading] = useState(false);

    const generateCsv = async () => {
        let error = null;
        try {
            setLoading(true);
            const response = await api().get(`${endpoint}`, { responseType: 'blob' });
            const blob = new Blob([response.data], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${name}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (e) {
            error = handleApiError(e, 'Error inesperado generando csv.')
        } finally {
            if (error) showSnackbar(error, 'error');
            setLoading(false);
        }
    };

    return (
        <Button
            variant="contained"
            sx={{ backgroundColor: 'green', color: 'white', ...sx }}
            onClick={generateCsv}
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
        >
            {loading ? '' : text}
        </Button>
    );
};

export const CreatePdfFromList = ({
    endpoint,
    list,
    text = 'Crear pdf',
    sx = {},
}) => {
    const { api } = useAuth();
    const showSnackbar = useSnackbar();

    const [loading, setLoading] = useState(false);

    const createPdf = async () => {
        let error = null;
        try {
            setLoading(true);
            const response = await api().post(`${endpoint}`, list, { responseType: 'blob' });
            const pdfUrl = URL.createObjectURL(response.data);
            window.open(pdfUrl, '_blank');

        } catch (e) {
            error = handleApiError(e, 'Error inesperado creando pdf.')
        } finally {
            if (error) showSnackbar(error, 'error');
            setLoading(false);
        }
    };
    return (
        <Button
            variant="contained"
            sx={{ backgroundColor: 'green', color: 'white', marginRight: '10px' }}
            onClick={createPdf}
            disabled={loading || list.length === 0}
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
        >
            {loading ? '' : text}
        </Button>
    );

};

export const CreateCSVFromList = ({
    endpoint,
    list,
    text = 'Crear csv',
}) => {
    const { api } = useAuth();
    const showSnackbar = useSnackbar();

    const [loading, setLoading] = useState(false);

    const createCsv = async () => {
        let error = null;
        try {
            setLoading(true);
            const response = await api().post(`${endpoint}`, list, { responseType: 'blob' });
            const blob = new Blob([response.data], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `albaranes.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (e) {
            error = handleApiError(e, 'Error inesperado creando csv.')
        } finally {
            if (error) showSnackbar(error, 'error');
            setLoading(false);
        }
    };
    return (
        <Button
            variant="contained"
            sx={{ backgroundColor: 'green', color: 'white', marginRight: '10px' }}
            onClick={createCsv}
            disabled={loading || list.length === 0}
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
        >
            {loading ? '' : text}
        </Button>
    );

};

export const UploadDocumentButton = ({ to, id, fetch }) => {

    const { api } = useAuth();
    const showSnackbar = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [uploadError, setUploadError] = useState(null);

    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleUpload = async () => {
        let error = null;
        try {
            if (!selectedFile) {
                setUploadError("Por favor selecciona un archivo");
                return;
            }
            const formData = new FormData();
            formData.append('document', selectedFile);
            formData.append(to, id);

            setLoading(true);
            await api().post(`/documents/`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            showSnackbar('Documento subido correctamente');
            setUploadModalOpen(false);
            setSelectedFile(null);
            setUploadError(null);
            fetch();


        } catch (e) {
            error = handleApiError(e, 'Error inesperado subiendo documento.')

        } finally {
            if (error) {
                showSnackbar(error, 'error');
                setUploadError(error);
            }
            setLoading(false);
        }
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={() => setUploadModalOpen(true)}
                startIcon={<AddIcon />}
                sx={{ marginRight: '10px' }}
            >
                Subir documento
            </Button>

            <Dialog open={uploadModalOpen} onClose={() => setUploadModalOpen(false)}>
                <DialogTitle>Subir documento (pdf, word, excel o imagen hasta 20mb)</DialogTitle>
                <DialogContent>
                    <input type="file" onChange={handleFileChange} />
                    {uploadError && (
                        <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                            {uploadError}
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setUploadModalOpen(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleUpload}
                        color="primary"
                        variant="contained"
                        disabled={loading}
                    >
                        {loading ? 'Subiendo...' : 'Enviar'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export const CreateInvoice = ({
    isProviderInvoice,
    deliveryNotes,
    fetch,
    sx = {},
}) => {

    const { api } = useAuth();
    const showSnackbar = useSnackbar();

    const [open, setOpen] = useState(false);
    const [vat, setVat] = useState('');
    const [invoiceLimitDate, setInvoiceLimitDate] = useState(null);
    const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
    const [loading, setLoading] = useState(false);

    const url = isProviderInvoice ? 'invoices_provider' : 'invoices';

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setLoading(false);
    };

    const handleVatChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (value >= 0 && value <= 100) {
            setVat(value);
            checkSubmitEnabled(value, invoiceLimitDate);
        }
    };

    const handleDateChange = (newDate) => {
        const localDate = dayjs(newDate).local().format();
        setInvoiceLimitDate(localDate);
        checkSubmitEnabled(vat, localDate);
    };

    const checkSubmitEnabled = (vatValue, dateValue) => {
        setIsSubmitEnabled(vatValue !== '' && dateValue !== null);
    };

    const handleSubmit = async () => {
        setLoading(true);
        let error = null;
        try {
            
            await api().post(`/${url}/`, {
                delivery_notes: deliveryNotes,
                vat: vat,
                invoice_limit_date: invoiceLimitDate,
            });
            showSnackbar('Factura creada correctamente');
            fetch();
            handleClose();
        } catch (e) {
            error = handleApiError(e, 'Error inesperado creando factura.')
        } finally {
            if (error) showSnackbar(error, 'error');
            setLoading(false);
        }
    };

    return (
        <>
            <Button
                variant="contained"
                sx={{ backgroundColor: 'green', color: 'white', marginRight: '10px' , ...sx}}
                onClick={handleOpen}
                disabled={loading || deliveryNotes.length === 0}
                startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
            >
                Facturar
            </Button>
            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                    }}
                >
                    <Typography variant="h6" component="h2" mb={2}>
                        Crear Factura
                    </Typography>

                    {/* Campo IVA */}
                    <IntegerTextField
                        label="IVA (%)"
                        name="vat"
                        value={vat}
                        onChange={handleVatChange}
                        fullWidth
                        required
                        error={vat < 0 || vat > 100}
                        helperText={vat < 0 || vat > 100 ? "El IVA debe estar entre 0 y 100" : ''}
                    />
                    <br/>
                    <br/>

                    {/* Campo Fecha de vencimiento */}
                    <DatePickerComponent
                        label="Fecha de vencimiento"
                        value={invoiceLimitDate}
                        onChange={handleDateChange}
                    />

                    {/* Botón Aceptar */}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={!isSubmitEnabled || loading}
                        sx={{ mt: 2 }}
                        fullWidth
                    >
                        {loading ? <CircularProgress size={24} /> : 'Aceptar'}
                    </Button>
                </Box>
            </Modal>
        </>
    );
};

export const DownloadDocumentButton = ({ documentId, documentName }) => {
    const [loading, setLoading] = useState(false);
    const { api } = useAuth();
    const showSnackbar = useSnackbar();

    const handleDownloadDocument = async () => {
        setLoading(true);
        let error = null;
        try {
            const response = await api().get(`/documents/${documentId}`, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', documentName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (e) {
            error = handleApiError(e, 'Error inesperado descargando documento.');
        } finally {
            if (error) showSnackbar(error, 'error');
            setLoading(false);
        }
    };

    return (
        <Button onClick={handleDownloadDocument} disabled={loading} >
            {loading ? <CircularProgress size={24} /> : documentName}
        </Button>
    );
};

export default DownloadDocumentButton;

const styles = {
    accordionButton: {
        display: 'block',
        width: '100%',
        textAlign: 'left',
        margin: '10px 0',
        padding: '15px',
        backgroundColor: '#f1f1f1',
        border: '2px solid #ccc',
        cursor: 'pointer',
        fontSize: '15px',
    },
    accordionContent: {
        padding: '15px',
        border: '2px solid #ccc',
        borderTop: 'none',
        backgroundColor: '#fff',
    },
};
