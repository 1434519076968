import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../components/SnackbarProvider';
import { 
    handleApiError, 
    DatePickerComponent, 
    DecimalTextField, 
    formatDayjs, 
    formatUnit, 
    formatPrice, 
    formatNumber 
} from '../../components/Utils';
import { useAuth } from '../login/OAuth';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import "dayjs/locale/es";
import {
    Button,
    TextField,
    Grid,
    Box,
    Paper,
    Typography,
    CircularProgress,
    Autocomplete,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper as TablePaper,
    IconButton
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

dayjs.extend(utc);

function NewProviderOrder() {
    const { api } = useAuth();
    const navigate = useNavigate();
    const showSnackbar = useSnackbar();

    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);

    const [providers, setProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState(null);

    const [productGroups, setProductGroups] = useState([]);
    const [selectedProductGroup, setSelectedProductGroup] = useState(null);

    const [quarries, setQuarries] = useState([]);
    const [selectedQuarry, setSelectedQuarry] = useState(null);

    const [productForm, setProductForm] = useState([]);

    const initialNewProductState = {
        product_group_id: '',
        quarry_id: null,
        unit_price: '',
        quantity: '',
    };

    const [newProduct, setNewProduct] = useState(initialNewProductState);

    const [orderForm, setOrderForm] = useState({
        provider_id: null,
        provider_code: null,
        provider_order_date: dayjs().utc().format('YYYY-MM-DD'),
    });

    const fetchProviders = useCallback(async () => {
        try {
            setLoading(true);
            const result = await api().get("/providers/");
            setProviders(result.data[0]);
            setLoading(false);
        } catch (error) {
            setError('Error obteniendo proveedores.');
        }
    }, [api]);

    const fetchProducts = useCallback(async () => {
        try {

            const result = await api().get('/products/get_products_for_provider_order');
            setProductGroups(result.data);

        } catch (error) {
            setError('Error obteniendo productos.');
        }
    }, [api]);

    const fetchQuarries = useCallback(async () => {
        try {
            const result = await api().get('/quarries/');
            setQuarries(result.data[0]);
        } catch (error) {
            setError('Error obteniendo canteras.');
        }
    }, [api]);

    useEffect(() => {
        fetchProviders();
        fetchProducts();
        fetchQuarries();
    }, []);

    const handleDateChange = (newValue) => {
        let offerDate = formatDayjs(newValue);
        setOrderForm(prevState => ({
            ...prevState,
            provider_order_date: offerDate
        }));
    };

    const handleProviderChange = (event, newValue) => {
        setSelectedProvider(newValue);
        setOrderForm(prevState => ({
            ...prevState,
            provider_id: newValue?.id
        }));
    };

    const handleProductGroupChange = (event, newValue) => {
        setSelectedProductGroup(newValue);
        setNewProduct(prevState => ({
            ...prevState,
            product_group_id: newValue?.id
        }));
    };

    const handleQuarryChange = (event, newValue) => {
        setSelectedQuarry(newValue);
        setNewProduct(prevState => ({
            ...prevState,
            quarry_id: newValue?.id
        }));
    };
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setOrderForm(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const validateForm = () => {
        const errors = {};

        if (!orderForm.provider_id) {
            errors.provider_id = "Por favor, añada un proveedor.";
        }
        if (!orderForm.provider_order_date) {
            errors.provider_order_date = "Por favor, añada una fecha.";
        }
        if (orderForm.provider_code && orderForm.provider_code.length > 36) {
            errors.provider_code = "El código de proveedor no puede superar los 36 caracteres.";
        }
        if (productForm.length < 1) {
            errors.general = "Por favor, añada un artículo como mínimo.";
        }
        return Object.keys(errors).length > 0 ? errors : null;
    };

    const createOrder = async (event) => {
        let error = null;
        try {
            event.preventDefault();
            const validationError = validateForm();
            if (validationError) {
                setError(validationError);
                return;
            }
            setLoading(true);
            console.log(orderForm)
            console.log(productForm)
            await api().post('/provider_orders/', { ...orderForm, provider_order_details: productForm });

            showSnackbar("Pedido creado correctamente");
            setError(null);
            navigate(`/provider_orders/list`);

        } catch (e) {
            error = handleApiError(e, 'Error inesperado creando pedido.')
        } finally {
            if (error) setError(prevError => ({ ...prevError, general: error }));
            setLoading(false);
        }
    };

    const handleNewProductChange = (event) => {
        const { name, value } = event.target;

        let error = null;

        const regex = /^\d+(\.\d{0,2})?$/;

        if (name === "unit_price" && !regex.test(value)) {
            error = "El valor debe tener máximo dos decimales y ser mayor que 0.";

        } else if (name === "quantity" && !regex.test(value)) {
            error = "El valor debe tener máximo dos decimales y ser mayor que 0.";

        }
        setError(prevError => ({ ...prevError, [name]: error }))

        setNewProduct(prevState => ({
            ...prevState,
            [name]: value
        }));

    };

    const handleAddProduct = () => {

        if (newProduct.product_group_id &&
            (!selectedProductGroup.has_stock || newProduct.quarry_id) &&
            newProduct.unit_price &&
            newProduct.quantity) {
            setProductForm([...productForm, newProduct]);
            setNewProduct(initialNewProductState);
            setSelectedProductGroup(null);
            setSelectedQuarry(null);
        }
    };

    const handleRemoveProduct = (index) => {
        const newProductForm = productForm.filter((_, i) => i !== index);
        setProductForm(newProductForm);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Paper elevation={3} style={{ padding: 20, width: '80%' }}>
                <Typography component="h2" variant="h5">
                    Nuevo Pedido
                </Typography>
                <br />
                <form onSubmit={createOrder}>
                    <Grid container spacing={3} alignItems="center" wrap="nowrap">
                        <Grid item xs={12} md={3}>
                            <Autocomplete
                                options={providers}
                                getOptionLabel={(option) => option.code?.toString() + ' - ' + option.name?.toString()}
                                value={selectedProvider}
                                onChange={handleProviderChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Proveedor"
                                        fullWidth
                                        error={!!error?.provider_id}
                                        helperText={error?.provider_id}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                name="provider_code"
                                label="Código del pedido"
                                value={orderForm.provider_code}
                                inputProps={{ maxLength: 36 }}
                                onChange={handleInputChange}
                                fullWidth
                                error={!!error?.provider_code}
                                helperText={error?.provider_code}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <DatePickerComponent
                                label="Fecha"
                                value={orderForm.provider_order_date}
                                onChange={handleDateChange}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Typography variant="h6">Añadir Producto</Typography>
                    <br />
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12} md={3}>
                            <Autocomplete
                                options={productGroups}
                                getOptionLabel={(option) => option.name?.toString()}
                                value={selectedProductGroup}
                                onChange={handleProductGroupChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Producto"
                                        fullWidth
                                        error={!!error?.product_group_id}
                                        helperText={error?.product_group_id}
                                    />
                                )}
                            />
                        </Grid>
                        {selectedProductGroup && selectedProductGroup.has_stock && (
                            <Grid item xs={12} md={3}>
                                <Autocomplete
                                    options={quarries}
                                    getOptionLabel={(option) => option.name?.toString()}
                                    value={selectedQuarry}
                                    onChange={handleQuarryChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Cantera"
                                            fullWidth
                                            error={!!error?.quarry_id}
                                            helperText={error?.quarry_id}
                                        />
                                    )}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} md={3}>
                            <DecimalTextField
                                label="Precio por Unidad"
                                name="unit_price"
                                value={newProduct.unit_price}
                                onChange={handleNewProductChange}
                                error={!!error?.unit_price}
                                helperText={error?.unit_price}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <DecimalTextField
                                label="Cantidad"
                                name="quantity"
                                value={newProduct.quantity}
                                onChange={handleNewProductChange}
                                error={!!error?.quantity}
                                helperText={error?.quantity}
                            />
                        </Grid>
                        {selectedProductGroup && (
                            <Grid item xs={12}>
                                <Typography variant="body2" color="textSecondary">
                                    Recuerda que este producto se mide en {formatUnit(selectedProductGroup.unit)}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAddProduct}
                                disabled={
                                    !!error.unit_price
                                    || !!error.quantity
                                    || !newProduct.unit_price
                                    || !newProduct.quantity
                                    || !newProduct.product_group_id
                                    || (selectedProductGroup.has_stock && !newProduct.quarry_id)
                                }
                            >
                                Añadir Producto
                            </Button>
                        </Grid>
                    </Grid>
                    <br />
                    <TableContainer component={TablePaper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Producto</TableCell>
                                    <TableCell>Cantera</TableCell>
                                    <TableCell>Precio por Unidad</TableCell>
                                    <TableCell>Cantidad</TableCell>
                                    <TableCell>Unidad</TableCell>
                                    <TableCell>Total</TableCell>
                                    <TableCell>Acciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {productForm.map((product, index) => {
                                    const productName = productGroups.find(group => group.id === product.product_group_id)?.name || '-';
                                    const productUnit = productGroups.find(group => group.id === product.product_group_id)?.unit || '-';
                                    const quarryName = quarries.find(quarry => quarry.id === product.quarry_id)?.name || '-';
                                    const total = product.unit_price * product.quantity;
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>{productName}</TableCell>
                                            <TableCell>{quarryName}</TableCell>
                                            <TableCell>{formatPrice(product.unit_price)}</TableCell>
                                            <TableCell>{formatNumber(product.quantity)}</TableCell>
                                            <TableCell>{productUnit}</TableCell>
                                            <TableCell>{formatPrice(total)}</TableCell>
                                            <TableCell>
                                                <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveProduct(index)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box mt={2} display="flex" justifyContent="flex-end">
                        <Button type="submit" variant="contained" color="primary" disabled={loading}>
                            {loading ? <CircularProgress size={24} /> : 'Crear Pedido'}
                        </Button>
                    </Box>
                </form>
                {error?.general && (
                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                        {error?.general}
                    </Typography>
                )}
            </Paper>
        </div>
    );
}

export default NewProviderOrder;
