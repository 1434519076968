import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { useSnackbar } from '../../components/SnackbarProvider';
import InvoiceTable from '../../components/InvoiceTable';
import {
    DisableDialog,
    Accordion,
    handleApiError,
    formatDate,
    formatUnit
} from '../../components/Utils';
import {
    TextField,
    Button,
    Typography,
    Paper,
    Grid,
    Box,
    CircularProgress,
    List,
    ListItem,
    ListItemText,
    Autocomplete,
    IconButton,
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableContainer,
    TableBody
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function BuildingDetails() {

    const { id } = useParams();
    const { api } = useAuth();
    const showSnackbar = useSnackbar();
    const [loading, setLoading] = useState({ general: true, update: false });
    const [error, setError] = useState({});

    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);

    const [invoices, setInvoices] = useState([]);
    const [totalPriceSum, setTotalPriceSum] = useState(null);
    const [paidAmountSum, setPaidAmountSum] = useState(null);
    const [remainingAmountSum, setRemainingAmountSum] = useState(null);

    const [openDisableDialog, setOpenDisableDialog] = useState(false);
    const [disableId, setDisableId] = useState(null);
    const [navigateToWhenDisabled, setNavigateToWhenDisabled] = useState(false);
    const [endpointToDisable, setEndpointToDisable] = useState(false);

    const [buildingForm, setBuildingForm] = useState({
        name: null,
        clients: []
    });

    const fetchBuilding = useCallback(async () => {
        try {
            setLoading(prev => ({ ...prev, general: true }));
            const result = await api().get(`/buildings/${id}`);
            const data = result.data;

            setBuildingForm(data.building);
            setInvoices(data.invoices);
            setTotalPriceSum(data.total_price_sum);
            setPaidAmountSum(data.paid_amount_sum);
            setRemainingAmountSum(data.remaining_amount_sum);

        } catch (error) {
            setError(prevError => ({ ...prevError, fetch: "Error obteniendo obra" }));

        } finally {
            setLoading(prev => ({ ...prev, general: false }));
        }
    }, [api, id]);

    const fetchClients = useCallback(async () => {

        let fetchClientError = null;

        try {
            const result = await api().get('/clients/');
            if (result.status < 300) {
                setClients(result.data[0]);
            } else {
                fetchClientError = `Error ${result.status}: ${result.data.detail}`;
            }
        } catch (error) {
            fetchClientError = 'Error obteniendo clientes';

        } finally {
            setError(prevError => ({ ...prevError, general: fetchClientError }));
        }
    }, [api]);

    useEffect(() => {
        fetchBuilding();
        fetchClients();
    }, []);

    const handleChangeForm = (e) => {
        const { name, value } = e.target;
        setBuildingForm(prevBuildingForm => ({ ...prevBuildingForm, [name]: value }));
    };

    const validateForm = () => {
        const errors = {};

        if (!buildingForm.name) {
            errors.name = "Por favor, añada un nombre.";
        } else if (buildingForm.name.length > 36) {
            errors.name = "El nombre no debe exceder los 36 caracteres.";
        }
        return Object.keys(errors).length > 0 ? errors : null;
    };

    const handleUpdate = async (e) => {

        let error = null;

        try {
            e.preventDefault();
            setLoading(prev => ({ ...prev, update: true }));

            const validationError = validateForm();
            if (validationError) {
                setError(validationError);
                return;
            }

            const result = await api().patch(`/buildings/`, buildingForm);

            setBuildingForm(result.data);
            showSnackbar('Obra actualizado correctamente');

        } catch (e) {
            error = handleApiError(e, 'Error inesperado actualizando obra.')

        } finally {
            setError(prevError => ({ ...prevError, update: error }));
            setLoading(prev => ({ ...prev, update: false }));
        }
    };

    const handleDissociateClient = async (clientId) => {

        let error = null;

        try {
            const result = await api().post(`/buildings/disassociate_client`, { building_id: id, client_id: clientId });

            setBuildingForm(result.data);
            showSnackbar('Cliente desasociado correctamente');

        } catch (e) {
            error = handleApiError(e, 'Error inesperado liberando cliente.')
        } finally {
            if (error) {
                showSnackbar(error, 'error');
            }
        }
    };

    const handleAssociateClient = async () => {

        let error = null;

        try {
            const result = await api().post(`/buildings/associate_client`, { building_id: id, client_id: selectedClient.id });

            setBuildingForm(result.data);
            setSelectedClient(null);
            showSnackbar('Cliente asociado correctamente');

        } catch (e) {
            error = handleApiError(e, 'Error inesperado asociando cliente.')

        } finally {
            if (error) {
                showSnackbar(error, 'error');
            }
        }
    };

    const handleOpenDisableDialog = (itemId, endpoint, navigateTo) => {
        setDisableId(itemId);
        setEndpointToDisable(endpoint);
        setNavigateToWhenDisabled(navigateTo);
        setOpenDisableDialog(true);
    };

    return (
        <div style={{ padding: 20 }}>
            {loading?.general && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading?.general && (
                <div>
                    <Grid container spacing={3} style={{ position: 'fixed', top: 60, right: 10, zIndex: 1100, width: 'auto' }}>
                        <Grid item>
                            <Paper elevation={0} style={{ padding: '10px', textAlign: 'center', backgroundColor: '#fff' }}>
                                <Box mb={2}>
                                    <Typography variant="h5" component="h1" gutterBottom>
                                        {buildingForm.code} - {buildingForm.name}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={10} lg={8}>
                        {/* Edit building */}
                        <Accordion title={`${buildingForm.code} ${buildingForm.name}`}>
                            <form onSubmit={handleUpdate} style={{ padding: '20px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Nombre"
                                            name="name"
                                            value={buildingForm.name}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 36 }}
                                            error={!!error?.name}
                                            helperText={error?.name}
                                        />
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleOpenDisableDialog(id, '/buildings/disable', '/buildings/list')}
                                            fullWidth
                                            style={{ backgroundColor: 'red' }}>
                                            Eliminar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={loading?.update}
                                            fullWidth>
                                            {loading?.update ? (
                                                <CircularProgress size={24} color="inherit" />
                                            ) : (
                                                'Actualizar'
                                            )}
                                        </Button>
                                    </Grid>
                                </Grid>
                                {error.update && (
                                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                                        {error.update}
                                    </Typography>
                                )}
                            </form>
                        </Accordion>
                        {/* Associate clients */}
                        <Accordion title="Clientes Asociados">
                            <List>
                                {buildingForm?.clients.map(item => (
                                    <ListItem
                                        key={item.id}
                                        secondaryAction={
                                            <IconButton edge="end" aria-label="delete" onClick={() => handleDissociateClient(item.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        }>
                                        <ListItemText primary={item.name} />
                                    </ListItem>
                                ))}
                            </List>
                            <Autocomplete
                                options={clients}
                                getOptionLabel={(option) => option.code + ' - ' + option.name}
                                value={selectedClient}
                                onChange={(event, newValue) => setSelectedClient(newValue)}
                                renderInput={(params) => (
                                    <TextField {...params} label="Seleccionar cliente para asociar" variant="outlined" fullWidth />
                                )}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAssociateClient}
                                disabled={!selectedClient}
                                fullWidth
                                style={{ marginTop: 10 }}>
                                Asociar Cliente
                            </Button>
                        </Accordion>
                        <Accordion title="Albaranes">
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Código</TableCell>
                                            <TableCell>Fecha de Creación</TableCell>
                                            <TableCell>Neto</TableCell>
                                            <TableCell>Grupo de Producto</TableCell>
                                            <TableCell>Placa del Camión</TableCell>
                                            <TableCell>Nombre del Conductor</TableCell>
                                            <TableCell>Acciones</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {buildingForm.delivery_notes.filter(del_note => !del_note.disabled).map(del_note => (
                                            <TableRow key={del_note.id}>
                                                <TableCell>
                                                    <Link to={`/delivery_notes/${del_note.id}/details`}>
                                                        {del_note.code}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>{formatDate(del_note.date_created)}</TableCell>
                                                <TableCell>{del_note.net} {formatUnit(del_note.product.product_group.unit)}</TableCell>
                                                <TableCell>
                                                    <Link to={`/products/${del_note.product.product_group.id}/details`}>
                                                        {del_note.product.product_group.name}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    <Link to={`/trucks/${del_note.truck.id}/details`}>
                                                        {del_note.truck.license_plate || "-"}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    <Link to={`/truck_drivers/${del_note.truck_driver.id}/details`}>
                                                        {del_note.truck_driver.name || "-"}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="outlined"
                                                        color="secondary"
                                                        onClick={() => handleOpenDisableDialog(del_note.id, '/delivery_notes/disable')}
                                                    >
                                                        Eliminar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Accordion>
                        {/* Invoices Accordion */}
                        <InvoiceTable
                            isProviderInvoice={false}
                            showBuilding={false}
                            showClientOrProvider={true}
                            title='Facturas'
                            totalAmount={totalPriceSum}
                            paidAmount={paidAmountSum}
                            remainingAmountSum={remainingAmountSum}
                            invoices={invoices}
                            uploadDocument={false}
                            fetch={fetchBuilding}
                            handleOpenDeleteDialog={handleOpenDisableDialog}
                        />
                        <DisableDialog
                            open={openDisableDialog}
                            onClose={() => setOpenDisableDialog(false)}
                            endpoint={endpointToDisable}
                            disableId={disableId}
                            navigateTo={navigateToWhenDisabled}
                        />
                    </Grid>
                </div>)
            }
        </div >
    );
}

export default BuildingDetails;
