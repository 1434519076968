import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { handleApiError, DisableDialog, Accordion, formatDate, formatTime, formatAssignmentStatus, DecimalTextField } from '../../components/Utils';
import { useSnackbar } from '../../components/SnackbarProvider';
import {
    TextField,
    InputLabel,
    Select,
    FormControl,
    Button,
    Typography,
    Paper,
    Grid,
    Box,
    CircularProgress,
    Autocomplete,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Card,
    FormHelperText
} from '@mui/material';

function TrailerDetails() {
    const { id } = useParams();
    const { api } = useAuth();
    const showSnackbar = useSnackbar();
    const [loading, setLoading] = useState({ general: true, update: false });
    const [error, setError] = useState({});

    const [openDisableDialog, setOpenDisableDialog] = useState(false);
    const [disableId, setDisableId] = useState(null);
    const [navigateToWhenDisabled, setNavigateToWhenDisabled] = useState(false);
    const [endpointToDisable, setEndpointToDisable] = useState(false);

    const [lastSavedClient, setLastSavedClient] = useState(null); // used to bring only client info for autocompletes

    const [assignments, setAssignments] = useState([]);

    const [trucks, setTrucks] = useState([]);
    const [truckSelected, setTruckSelected] = useState(null);
    const [modalSelectTruckOpen, setModalSelectTruckOpen] = useState(false);

    const [trailerForm, setTrailerForm] = useState({
        license_plate: null,
        tare: null,
        truck_id: null,
        trailer_type: null,
    });


    const fetchTrailer = useCallback(async () => {
        try {
            setLoading(prev => ({ ...prev, general: true }));
            const result = await api().get(`/trailers/${id}`);
            const trailer = result.data;

            setTrailerForm(trailer);
            setLastSavedClient(trailer.client_id);
            setAssignments(trailer.assignments);

        } catch (exception_error) {
            setError(prevError => ({ ...prevError, fetch: "Error obteniendo remolque" }));

        } finally {
            setLoading(prev => ({ ...prev, general: false }));
        }
    }, [api, id]);

    const fetchTrucks = useCallback(async () => {
        let fetchTrucksError = null;

        try {
            const params = { client_id: lastSavedClient }
            const result = await api().get('/trucks/', { params });
            if (result.status < 300) {
                setTrucks(result.data[0]);
            } else {
                fetchTrucksError = `Error ${result.status}: ${result.data.detail}`;
            }
        } catch (error) {
            fetchTrucksError = 'Error obteniendo camiones.';
        } finally {
            setError(prevError => ({ ...prevError, fetchTrucksError: fetchTrucksError }));
        }
    }, [api, lastSavedClient]);

    useEffect(() => {
        fetchTrailer();
    }, []);

    useEffect(() => {
        fetchTrucks();
    }, [lastSavedClient]);

    const handleChangeForm = (e) => {
        const { name, value } = e.target;
        setTrailerForm(prevTrailerForm => ({ ...prevTrailerForm, [name]: value }));
    };

    const validateForm = () => {
        const errors = {};

        if (!trailerForm.license_plate) {
            errors.license_plate = "Por favor, añada una matrícula.";
        } else if (trailerForm.license_plate.length > 10) {
            errors.license_plate = "La matrícula no debe exceder los 10 caracteres.";
        }
        if (!trailerForm.tare) {
            errors.tare = "Por favor, añada una tara.";
        }
        if (!trailerForm.trailer_type) {
            errors.trailer_type = "Por favor, seleccione un tipo de trailer.";
        }

        return Object.keys(errors).length > 0 ? errors : null;
    };

    const handleUpdate = async (e) => {

        let error = null;

        try {
            e.preventDefault();
            setLoading(prev => ({ ...prev, update: true }));


            const validationError = validateForm();
            if (validationError) {
                setError(validationError);
                return;
            }
            const result = await api().patch(`/trailers/`, trailerForm);
            
            setTrailerForm(result.data);
            showSnackbar('Remolque actualizado correctamente');

        } catch (e) {
            error = handleApiError(e, 'Error inesperado actualizando remolque.')

        } finally {
            setError(prevError => ({ ...prevError, update: error }));
            setLoading(prev => ({ ...prev, update: false }));
        }
    };

    const handleAssociateTruck = async (e) => {

        let error = null;

        try {
            e.preventDefault();
            setLoading(prev => ({ ...prev, associate: true }));

            const result = await api().patch(`/trailers/associate_truck`, { trailer_id: id, truck_id: truckSelected.id });
            
            setTrailerForm(result.data);
            setAssignments(result.data.assignments);
            setModalSelectTruckOpen(false);
            showSnackbar('Camión asociado correctamente');
                
        } catch (e) {
            error = handleApiError(e, 'Error inesperado asociando camión.')
        } finally {
            if (error) {
                showSnackbar(error, 'error');
            }
            setLoading(prev => ({ ...prev, associate: false }));
        }
    };

    const handleReleaseTruck = async (e) => {

        let error = null;

        try {
            e.preventDefault();
            setLoading(prev => ({ ...prev, release: true }));

            const result = await api().patch(`/trailers/release_truck`, { id: id });
            
            setTrailerForm(result.data);
            setAssignments(result.data.assignments);
            showSnackbar('Camión liberado correctamente.');

        } catch (e) {
            error = handleApiError(e, 'Error inesperado liberando camión.')

        } finally {
            if (error) {
                showSnackbar(error, 'error');
            }
            setLoading(prev => ({ ...prev, release: false }));
        }
    };

    const handleOpenDisableDialog = (itemId, endpoint, navigateTo) => {
        setDisableId(itemId);
        setEndpointToDisable(endpoint);
        setNavigateToWhenDisabled(navigateTo);
        setOpenDisableDialog(true);
    };

    return (
        <div style={{ padding: 20 }}>
            {loading?.general && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading?.general && (
                <div>
                    <Grid container spacing={3} style={{ position: 'fixed', top: 60, right: 10, zIndex: 1100, width: 'auto' }}>
                        <Grid item>
                            <Paper elevation={0} style={{ padding: '10px', textAlign: 'center', backgroundColor: '#fff' }}>
                                <Box mb={2}>
                                    <Typography variant="h5" component="h1" gutterBottom>
                                        {trailerForm.code} - {trailerForm.license_plate}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={10} lg={8}>
                        <Accordion title={`${trailerForm.code} - ${trailerForm.license_plate}`}>
                            <form onSubmit={handleUpdate} style={{ padding: '20px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Matrícula"
                                            name="license_plate"
                                            value={trailerForm.license_plate}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 10 }}
                                            error={!!error?.license_plate}
                                            helperText={error?.license_plate}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <DecimalTextField
                                            label="Tara"
                                            name="tare"
                                            value={trailerForm.tare}
                                            onChange={handleChangeForm}
                                            error={!!error?.tare}
                                            helperText={error?.tare}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <FormControl
                                            fullWidth
                                            error={!!error?.trailer_type}
                                        >
                                            <InputLabel id="trailer-type-label">
                                                Tipo de Remolque
                                            </InputLabel>
                                            <Select
                                                labelId="trailer-type-label"
                                                id="trailer-type"
                                                label='Tipo Trailer'
                                                value={trailerForm.trailer_type}
                                                onChange={handleChangeForm}
                                                name="trailer_type"
                                                inputProps={{ id: 'trailer-type' }}
                                            >
                                                <MenuItem value="Carrillero_3_ejes_rigidos">CARRILLERO 3 EJES RÍGIDOS</MenuItem>
                                                <MenuItem value="Bañera_2_ejes">BAÑERA 2 EJES</MenuItem>
                                                <MenuItem value="Bañera_3_ejes">BAÑERA 3 EJES</MenuItem>
                                                <MenuItem value="Camion_15_tn">CAMION_15_TN</MenuItem>
                                            </Select>
                                            {error?.trailer_type && (
                                                <FormHelperText>{error.trailer_type}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleOpenDisableDialog(id, '/trailers/disable', '/trailers/list')}
                                            fullWidth
                                            style={{ backgroundColor: 'red' }}>
                                            Eliminar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={loading?.update}
                                            fullWidth>
                                            {loading?.update ? (
                                                <CircularProgress size={24} color="inherit" />
                                            ) : (
                                                'Actualizar'
                                            )}
                                        </Button>
                                    </Grid>
                                </Grid>
                                {error.update && (
                                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                                        {error.update}
                                    </Typography>
                                )}
                            </form>
                        </Accordion>
                        <Accordion title='Camiones'>
                            <Box mt={2}>
                                <Typography variant="h6">Detalles del Camión:</Typography>

                                {/* Si hay un trailer asociado */}
                                {trailerForm.truck ? (
                                    <>
                                        <Card variant="outlined" style={{ padding: '16px', marginTop: '16px' }}>
                                            <Typography variant="subtitle1"><strong>Matrícula:</strong>
                                                <Link to={`/trucks/${trailerForm.truck.id}/details`}>
                                                    {trailerForm.truck.license_plate}
                                                </Link>
                                            </Typography>
                                            <Typography variant="subtitle1"><strong>Tara:</strong> {trailerForm.truck.tare} Kg</Typography>
                                        </Card>

                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            style={{ marginTop: '16px' }}
                                            onClick={handleReleaseTruck}
                                        >
                                            Liberar Camión
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        {/* Si no hay camión asociado */}
                                        <Typography variant="subtitle1" style={{ marginTop: '16px' }}>No hay camión asociado.</Typography>

                                        <Box mt={2}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                style={{ marginRight: '10px' }}
                                                onClick={() => setModalSelectTruckOpen(true)}
                                            >
                                                Asociar Camión
                                            </Button>
                                        </Box>
                                    </>
                                )}
                            </Box>

                            {/* Tabla de Asignaciones */}
                            <Box mt={4}>
                                <Typography variant="h6">Asignaciones del Conductor:</Typography>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Código</TableCell>
                                                <TableCell>Nombre</TableCell>
                                                <TableCell>Estado</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {assignments.length > 0 ? (
                                                assignments.filter(item => item.truck_id).map((item, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            <Link to={`/trucks/${item.truck.id}/details`}>
                                                                {item.truck?.license_plate || '-'}
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell>{formatDate(item.date_created) + ' ' + formatTime(item.date_created) || '-'}</TableCell>
                                                        <TableCell>{formatAssignmentStatus(item.assignment_status)}</TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (
                                                <TableRow>
                                                    <TableCell colSpan={2} align="center">No hay asignaciones</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Accordion>

                        {/* Modal para seleccionar Camión */}
                        <Dialog
                            open={modalSelectTruckOpen}
                            onClose={() => setModalSelectTruckOpen(false)}
                            maxWidth="sm"
                            fullWidth>
                            <DialogTitle>Seleccionar Camión</DialogTitle>
                            <br />
                            <DialogContent>
                                <Autocomplete
                                    options={trucks}
                                    getOptionLabel={(option) => option.license_plate}
                                    onChange={(event, newValue) => setTruckSelected(newValue)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Camión" variant="outlined" fullWidth />
                                    )}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setModalSelectTruckOpen(false)} color="primary">
                                    Cancelar
                                </Button>
                                <Button onClick={handleAssociateTruck} disabled={!truckSelected} color="primary">
                                    Asociar
                                </Button>
                            </DialogActions>
                        </Dialog>


                        <DisableDialog
                            open={openDisableDialog}
                            onClose={() => setOpenDisableDialog(false)}
                            endpoint={endpointToDisable}
                            disableId={disableId}
                            navigateTo={navigateToWhenDisabled}
                        />
                    </Grid>
                </div>)
            }
        </div >
    );
}

export default TrailerDetails;
