import React, { useState, useEffect, useCallback } from "react";
import Paginate from '../../components/Paginate';
import { itemsPerPage } from '../../components/Utils';
import { Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Button,
    Grid,
    CircularProgress,
    Typography,
    Autocomplete
} from "@mui/material";

function ListTruckDriver() {
    const { api } = useAuth();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [fetchReset, setFetchReset] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(null);

    const [truckDrivers, setTruckDrivers] = useState([]);

    const [clients, setClients] = useState([]);
    const [clientSelected, setClientSelected] = useState(null);

    const [code, setCode] = useState(null);
    const [name, setName] = useState(null);
    const [documentation, setDocumentation] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);

    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('ASC');

    const fetchTruckDrivers = useCallback(async () => {
        try {
            setLoading(true);
            const params = {};
            params.limit = itemsPerPage;
            params.offset = (currentPage - 1) * itemsPerPage;

            if (!fetchReset) {
                if (name) params.name = name;
                if (code) params.code = code;
                if (email) params.email = email;
                if (phone) params.phone = phone;
                if (documentation) params.documentation = documentation;
                if (clientSelected) params.client_id = clientSelected;
                if (sortColumn) params.sort_by = sortColumn;
                if (sortDirection) params.sort_direction = sortDirection;
            } else {
                setFetchReset(false);
            }

            const result = await api().get("/truck_drivers/", { params });

            setTruckDrivers(result.data[0]);
            setTotalItems(result.data[1]);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api, currentPage, clientSelected, fetchReset, code, name, email, phone, documentation, sortColumn, sortDirection]);

    const fetchClients = useCallback(async () => {
        let fetchClientError = null;

        try {
            const result = await api().get('/clients/');
            if (result.status < 300) {
                setClients(result.data[0]);
            } else {
                fetchClientError = `Error ${result.status}: ${result.data.detail}`;
            }
        } catch (error) {
            fetchClientError = 'Error obteniendo clientes.';
        } finally {
            setError(prevError => ({ ...prevError, general: fetchClientError }));
        }
    }, [api]);

    useEffect(() => {
        fetchTruckDrivers();
    }, [currentPage, sortColumn, sortDirection, fetchReset]);

    useEffect(() => {
        fetchClients();
    }, []);

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
        } else {
            setSortColumn(column);
            setSortDirection('ASC');
        }
        setCurrentPage(1);
    };

    const handleFilters = (action) => {
        setCurrentPage(1);

        if (action === "reset") {
            setName(null);
            setDocumentation(null);
            setEmail(null);
            setCode(null);
            setPhone(null);
            setClientSelected(null);
            setFetchReset(true);
        }

        else if (action === "apply") {
            fetchTruckDrivers();
        }
    };

    const cellStyle = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '75px'
    };

    return (
        <div>
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={3} sm={2}>
                                    <TextField
                                        fullWidth
                                        label="Código"
                                        name="code"
                                        value={code || ""}
                                        onChange={(e) => setCode(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <TextField
                                        fullWidth
                                        label="Nombre"
                                        name="name"
                                        value={name || ""}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <TextField
                                        fullWidth
                                        label="Email"
                                        name="email"
                                        value={email || ""}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <TextField
                                        fullWidth
                                        label="Documentación"
                                        name="documentation"
                                        value={documentation || ""}
                                        onChange={(e) => setDocumentation(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <TextField
                                        fullWidth
                                        label="Teléfono"
                                        name="phone"
                                        value={phone || ""}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <Autocomplete
                                        options={clients}
                                        getOptionLabel={(option) => option.name?.toString()}
                                        value={clientSelected}
                                        onChange={(e, newValue) => setClientSelected(newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Cliente"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1} justifyContent="flex-end">
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={() => handleFilters("apply")}>
                                        Aplicar Filtros
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" color="secondary" onClick={() => handleFilters("reset")}>
                                        Resetear Filtros
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                    <br />
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('code')} style={{ cursor: 'pointer' }}>
                                                Código {sortColumn === 'code' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('name')} style={{ cursor: 'pointer' }}>
                                                Nombre {sortColumn === 'name' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('client_code')} style={{ cursor: 'pointer' }}>
                                                Cliente {sortColumn === 'client_code' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('email')} style={{ cursor: 'pointer' }}>
                                                Email {sortColumn === 'email' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('documentation')} style={{ cursor: 'pointer' }}>
                                                Documentación {sortColumn === 'documentation' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('phone')} style={{ cursor: 'pointer' }}>
                                                Teléfono {sortColumn === 'phone' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {truckDrivers.map(item => (
                                    <TableRow key={item.id}>
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            <Link to={`/truck_drivers/${item.id}/details`}>
                                                {item.code || '-'}
                                            </Link>
                                        </TableCell>
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            {(item.name || item.surname) ? `${item.name || ''} ${item.surname || ''}` : '-'}
                                        </TableCell>
                                        <TableCell sx={cellStyle}>
                                            <Link to={`/clients/${item.client.id}/details`}>
                                                {item.client.code || '-'}
                                            </Link>
                                        </TableCell>
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            {item.email || '-'}
                                        </TableCell>
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            {item.documentation || '-'}
                                        </TableCell>
                                        <TableCell sx={cellStyle}>
                                            {item.phone || '-'}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
            <br />
            <br />
            <br />
            <Paginate
                totalItems={totalItems}
                onPageChange={setCurrentPage}
                currentPage={currentPage}
            />
            {error?.general && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error.general}
                </Typography>
            )}
        </div>
    );
}

export default ListTruckDriver;