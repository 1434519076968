import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { useSnackbar } from '../../components/SnackbarProvider';
import InvoiceTable from '../../components/InvoiceTable';
import {
    handleApiError,
    DisableDialog,
    Accordion,
    formatPrice,
    formatUnit,
    formatAssignmentStatus
} from '../../components/Utils';
import {
    TextField,
    InputLabel,
    Select,
    FormControl,
    Button,
    Typography,
    Paper,
    Grid,
    Box,
    CircularProgress,
    Autocomplete,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Card
} from '@mui/material';

function QuarryDetails() {
    const { id } = useParams();
    const { api } = useAuth();
    const showSnackbar = useSnackbar();
    const [loading, setLoading] = useState({ general: true, update: false });
    const [error, setError] = useState({});

    const [openDisableDialog, setOpenDisableDialog] = useState(false);
    const [disableId, setDisableId] = useState(null);
    const [navigateToWhenDisabled, setNavigateToWhenDisabled] = useState(false);
    const [endpointToDisable, setEndpointToDisable] = useState(false);

    const [clientInvoices, setClientInvoices] = useState([]);
    const [clientTotalPriceSum, setClientTotalPriceSum] = useState(null);
    const [clientPaidAmountSum, setClientPaidAmountSum] = useState(null);
    const [clientRemainingAmountSum, setClientRemainingAmountSum] = useState(null);

    const [providerInvoices, setProviderInvoices] = useState([]);
    const [providerTotalPriceSum, setProviderTotalPriceSum] = useState(null);
    const [providerPaidAmountSum, setProviderPaidAmountSum] = useState(null);
    const [providerRemainingAmountSum, setProviderRemainingAmountSum] = useState(null);

    const [quarryForm, setQuarryForm] = useState({});

    const fetchQuarry = useCallback(async () => {
        try {
            setLoading(prev => ({ ...prev, general: true }));
            const result = await api().get(`/quarries/${id}`);
            const data = result.data;

            setQuarryForm(data.quarry);

            setClientInvoices(data.client_invoices);
            setClientTotalPriceSum(data.client_total_price_sum);
            setClientPaidAmountSum(data.client_paid_amount_sum);
            setClientRemainingAmountSum(data.client_remaining_amount_sum);

            setProviderInvoices(data.provider_invoices);
            setProviderTotalPriceSum(data.provider_total_price_sum);
            setProviderPaidAmountSum(data.provider_paid_amount_sum);
            setProviderRemainingAmountSum(data.provider_remaining_amount_sum);

        } catch (exception_error) {
            setError(prevError => ({ ...prevError, fetch: "Error obteniendo cantera" }));

        } finally {
            setLoading(prev => ({ ...prev, general: false }));
        }
    }, [api, id]);


    useEffect(() => {
        fetchQuarry();
    }, []);

    const handleChangeForm = (e) => {
        const { name, value } = e.target;
        setQuarryForm(prevquarryForm => ({ ...prevquarryForm, [name]: value }));
    };

    const validateForm = () => {
        const errors = {};

        if (!quarryForm.name) {
            errors.name = "Por favor, añada un nombre.";
        } else if (quarryForm.name && quarryForm.name.length > 100) {
            errors.name = "El nombre no debe exceder los 100 caracteres.";
        }
        if (quarryForm.address && quarryForm.address.length > 100) {
            errors.address = "La dirección no debe exceder los 100 caracteres.";
        }
        if (quarryForm.description && quarryForm.description.length > 255) {
            errors.description = "La descripción no debe exceder los 255 caracteres.";
        }
        return Object.keys(errors).length > 0 ? errors : null;
    };


    const handleUpdate = async (e) => {

        let error = null;

        try {
            e.preventDefault();
            setLoading(prev => ({ ...prev, update: true }));

            const validationError = validateForm();
            if (validationError) {
                setError(validationError);
                return;
            }

            const result = await api().patch(`/quarries/`, quarryForm);
           
            setQuarryForm(result.data);
            showSnackbar('Cantera actualizada correctamente');

        } catch (e) {
            console.log(e)
            error = handleApiError(e, 'Error inesperado actualizando cantera.')

        } finally {
            setError(prevError => ({ ...prevError, update: error }));
            setLoading(prev => ({ ...prev, update: false }));
        }
    };

    const handleOpenDisableDialog = (itemId, endpoint, navigateTo) => {
        setDisableId(itemId);
        setEndpointToDisable(endpoint);
        setNavigateToWhenDisabled(navigateTo);
        setOpenDisableDialog(true);
    };

    return (
        <div style={{ padding: 20 }}>
            {loading?.general && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading?.general && (
                <div>
                    <Grid container spacing={3} style={{ position: 'fixed', top: 60, right: 10, zIndex: 1100, width: 'auto' }}>
                        <Grid item>
                            <Paper elevation={0} style={{ padding: '10px', textAlign: 'center', backgroundColor: '#fff' }}>
                                <Box mb={2}>
                                    <Typography variant="h5" component="h1" gutterBottom>
                                        {quarryForm.code} - {quarryForm.name}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={10} lg={8}>
                        <Accordion title={`${quarryForm.code} ${quarryForm.name}`}>
                            <form onSubmit={handleUpdate} style={{ padding: '20px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            label="Nombre de la cantera"
                                            value={quarryForm.name}
                                            name="name"
                                            onChange={handleChangeForm}
                                            fullWidth
                                            inputProps={{ maxLength: 100 }}
                                            margin="normal"
                                            error={!!error?.name}
                                            helperText={error?.name}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={12}>
                                        <TextField
                                            fullWidth
                                            label="Dirección"
                                            name="address"
                                            value={quarryForm.address}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 55 }}
                                            error={!!error?.address}
                                            helperText={error?.address}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={12}>
                                        <TextField
                                            label="Descripción"
                                            name="description"
                                            value={quarryForm.description}
                                            onChange={handleChangeForm}
                                            fullWidth
                                            inputProps={{ maxLength: 255 }}
                                            multiline
                                            rows={3}
                                            margin="normal"
                                            error={!!error?.description}
                                            helperText={error?.description}
                                        />
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleOpenDisableDialog(id, '/quarries/disable', '/quarries/list')}
                                            fullWidth
                                            style={{ backgroundColor: 'red' }}>
                                            Eliminar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={loading?.update}
                                            fullWidth>
                                            {loading?.update ? (
                                                <CircularProgress size={24} color="inherit" />
                                            ) : (
                                                'Actualizar'
                                            )}
                                        </Button>
                                    </Grid>
                                </Grid>
                                {error.update && (
                                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                                        {error.update}
                                    </Typography>
                                )}
                            </form>
                        </Accordion>

                        <Accordion title="Productos de la Cantera">
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Código</TableCell>
                                            <TableCell>Nombre</TableCell>
                                            <TableCell>Stock</TableCell>
                                            <TableCell>Precio</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {quarryForm.products.filter(product => !product.disabled).map(product => (
                                            <TableRow key={product.id}>
                                                <TableCell>
                                                    <Link to={`/products/${product.product_group.id}/details`}>
                                                        {product.product_group.code}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>{product.product_group.name}</TableCell>
                                                <TableCell>{product.stock} {formatUnit(product.product_group.unit)}</TableCell>
                                                <TableCell>{formatPrice(product.price)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Accordion>

                        {/* Invoices Accordion */}
                        <InvoiceTable
                            isProviderInvoice={false}
                            showBuilding={true}
                            showClientOrProvider={true}
                            title='Facturas clientes'
                            totalAmount={clientTotalPriceSum}
                            paidAmount={clientPaidAmountSum}
                            remainingAmountSum={clientRemainingAmountSum}
                            invoices={clientInvoices}
                            uploadDocument={false}
                            fetch={fetchQuarry}
                            handleOpenDeleteDialog={handleOpenDisableDialog}
                        />

                        {/* Invoices Accordion */}
                        <InvoiceTable
                            isProviderInvoice={true}
                            showBuilding={false}
                            showClientOrProvider={true}
                            title='Facturas proveedores'
                            totalAmount={providerTotalPriceSum}
                            paidAmount={providerPaidAmountSum}
                            remainingAmountSum={providerRemainingAmountSum}
                            invoices={providerInvoices}
                            uploadDocument={true}
                            fetch={fetchQuarry}
                            handleOpenDeleteDialog={handleOpenDisableDialog}
                        />
                        <DisableDialog
                            open={openDisableDialog}
                            onClose={() => setOpenDisableDialog(false)}
                            endpoint={endpointToDisable}
                            disableId={disableId}
                            navigateTo={navigateToWhenDisabled}
                        />
                    </Grid>
                </div>)
            }
        </div >
    );
}

export default QuarryDetails;
