import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { DisableDialog, Accordion, DecimalTextField, formatDate, formatPrice, handleApiError } from '../../components/Utils';
import { useSnackbar } from '../../components/SnackbarProvider';
import { Delete as DeleteIcon } from "@mui/icons-material";
import ProductCosts from '../products/NewCostDetailsComponent';
import {
    TextField,
    Button,
    Typography,
    Paper,
    Grid,
    Box,
    CircularProgress,
    Autocomplete,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    IconButton,
    TableCell,
    TableRow,
    TableHead,
    TableBody,
    Table,
    TableContainer,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle
} from '@mui/material';

function ProductDetails() {

    const { id } = useParams();
    const { api } = useAuth();
    const showSnackbar = useSnackbar();
    const [loading, setLoading] = useState({ general: true, update: false });
    const [error, setError] = useState({});

    const [quarries, setQuarries] = useState([]);

    const [openDisableDialog, setOpenDisableDialog] = useState(false);
    const [disableId, setDisableId] = useState(null);
    const [navigateToWhenDisabled, setNavigateToWhenDisabled] = useState(false);
    const [endpointToDisable, setEndpointToDisable] = useState(false);

    const [openNewCostModal, setOpenNewCostModal] = useState(false);
    const [newCost, setNewCost] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const [openAddStockModal, setOpenAddStockModal] = useState(false);
    const [selectedQuarry, setSelectedQuarry] = useState(null);
    const [newStockPrice, setNewStockPrice] = useState('');
    const [newStockValue, setNewStockValue] = useState('');

    const [productForm, setProductForm] = useState({
        name: null,
        price: null,
        unit: null,
        stock: null,
        quarry_id: null,
        description: null,
        products: []
    });

    const fetchProduct = useCallback(async () => {
        try {
            setLoading(prev => ({ ...prev, general: true }));
            const result = await api().get(`/products/${id}`);
            const product = result.data
            setProductForm(product);

        } catch (error) {
            setError(prevError => ({ ...prevError, fetch: "Error obteniendo producto" }));

        } finally {
            setLoading(prev => ({ ...prev, general: false }));
        }
    }, [api, id]);

    const fetchQuarries = useCallback(async () => {

        let fetchQuarryError = null;

        try {
            const params = {}
            params.product_group_id = id;
            const result = await api().get('/quarries/', {params});
            if (result.status < 300) {
                setQuarries(result.data[0]);
            } else {
                fetchQuarryError = `Error ${result.status}: ${result.data.detail}`;
            }
        } catch (error) {
            fetchQuarryError = 'Error obteniendo canteras.';

        } finally {
            setError(prevError => ({ ...prevError, general: fetchQuarryError }));
        }
    }, [api]);

    useEffect(() => {
        fetchProduct();
        fetchQuarries();
    }, []);

    const handleChangeForm = (e) => {
        const { name, value } = e.target;
        setProductForm(prevProductForm => ({ ...prevProductForm, [name]: value }));
    };

    const handleAddStockToQuarry = async () => {
        let error = null;
        try {
            if (!selectedQuarry || !newStockPrice || !newStockValue) {
                showSnackbar('Por favor, complete todos los campos.', 'error');
                return;
            }

            const payload = {
                quarry_id: selectedQuarry.id,
                price: newStockPrice,
                stock: newStockValue,
                product_id: id
            };

            const result = await api().post(`/products/add_stock`, payload);

            if (result.status < 300) {
                showSnackbar('Stock añadido correctamente', 'success');
                fetchProduct(); // Volvemos a traer el producto actualizado
                setOpenAddStockModal(false); // Cerramos el modal
            }
        } catch (e) {
            error = handleApiError(e, 'Error inesperado añadir stock.')
        } finally {
            showSnackbar(error, 'error');
            setLoading(prev => ({ ...prev, update: false }));
        }
    };

    const validateForm = () => {
        const errors = {};

        if (!productForm.name) {
            errors.name = "Por favor, añada un nombre.";
        } else if (productForm.name.length > 36) {
            errors.name = "El nombre no debe exceder los 36 caracteres.";
        }
        if (!productForm.unit) {
            errors.unit = "Por favor, añada la unidad.";
        }

        return Object.keys(errors).length > 0 ? errors : null;
    };

    const handleUpdate = async (e) => {

        let error = null;

        try {
            e.preventDefault();
            setLoading(prev => ({ ...prev, update: true }));

            const validationError = validateForm();
            if (validationError) {
                setError(validationError);
                return;
            }

            const result = await api().patch(`/products/update_product_group`, productForm);

            setProductForm(result.data);
            showSnackbar('Producto actualizado correctamente');

        } catch (e) {
            error = handleApiError(e, 'Error inesperado actualizando gasto.')

        } finally {
            setError(prevError => ({ ...prevError, update: error }));
            setLoading(prev => ({ ...prev, update: false }));
        }
    };

    const handleAddCost = async (e) => {

        let error = null;

        try {
            e.preventDefault();
            setError(prevError => ({ ...prevError, addCostError: null }));

            const result = await api().post(`/costs/`, { product_id: selectedProduct, cost_details: newCost });
            showSnackbar('Gasto añadido correctamente');
            const product = result.data
            setProductForm(product);
            setOpenNewCostModal(false);
            setNewCost([]);

        } catch (e) {
            error = handleApiError(e, 'Error inesperado añadiendo gasto.')

        } finally {
            if (error) showSnackbar(error, 'error')
            setLoading(prev => ({ ...prev, addCostError: false }));
        }
    };

    const handleUpdateProduct = async (productId) => {
        let error = null;

        try {
            const productToUpdate = productForm.products.find(p => p.id === productId);
            const result = await api().patch(`/products/update_product`, productToUpdate);
            const product = result.data;

            showSnackbar('Producto actualizado correctamente');
            setProductForm(product);

        } catch (e) {
            error = handleApiError(e, 'Error inesperado actualizando producto .')

        } finally {
            if (error) showSnackbar(error, 'error')
        }
    };

    const handleOpenDisableDialog = (itemId, endpoint, navigateTo) => {
        setDisableId(itemId);
        setEndpointToDisable(endpoint);
        setNavigateToWhenDisabled(navigateTo);
        setOpenDisableDialog(true);
    };

    const handleOpenNewCostModal = (productId) => {
        setOpenNewCostModal(true);
        setSelectedProduct(productId);
    };

    const handleCloseNewStockDialog = () => {
        setNewStockPrice(null);
        setNewStockValue(null);
        setSelectedQuarry(null);
        setOpenAddStockModal(false);
    };

    const handleChangeProduct = (productId, fieldName, value) => {
        setProductForm(prevProductForm => ({
            ...prevProductForm,
            products: prevProductForm.products.map(product =>
                product.id === productId
                    ? { ...product, [fieldName]: value } // Solo actualiza el producto correspondiente
                    : product
            )
        }));
    };

    return (
        <div style={{ padding: 20 }}>
            {loading?.general && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading?.general && (
                <div>
                    <Grid container spacing={3} style={{ position: 'fixed', top: 60, right: 10, zIndex: 1100, width: 'auto' }}>
                        <Grid item>
                            <Paper elevation={0} style={{ padding: '10px', textAlign: 'center', backgroundColor: '#fff' }}>
                                <Box mb={2}>
                                    <Typography variant="h5" component="h1" gutterBottom>
                                        {productForm.code} - {productForm.name}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={10} lg={8}>
                        {/* Edit product */}
                        <Accordion title={`${productForm.code} ${productForm.name}`}>
                            <form onSubmit={handleUpdate} style={{ padding: '20px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Nombre"
                                            name="name"
                                            value={productForm.name}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 36 }}
                                            error={!!error?.name}
                                            helperText={error?.name}
                                        />
                                    </Grid>
                                    {productForm.has_stock && (
                                        <Grid item xs={10} md={2}>
                                            <DecimalTextField
                                                label="Stock"
                                                name="stock"
                                                value={productForm.products?.reduce((total, product) => total + (product.stock || 0), 0)}
                                                InputProps={{ readOnly: true }}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={10} md={2}>
                                        <FormControl
                                            style={{ width: "100%" }}
                                        >
                                            <InputLabel id="unit-label">
                                                Unidades
                                            </InputLabel>
                                            <Select
                                                labelId="unit-label"
                                                id="unit"
                                                label='Unidades'
                                                value={productForm.unit}
                                                onChange={handleChangeForm}
                                                name="unit"
                                                inputProps={{ id: 'unit' }}
                                                error={!!error?.unit}
                                                helperText={error?.unit}
                                            >
                                                <MenuItem value="Tn">Tn</MenuItem>
                                                <MenuItem value="kg">KG</MenuItem>
                                                <MenuItem value="l">L</MenuItem>
                                                <MenuItem value="uds">UDS</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                </Grid>
                                <br />
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleOpenDisableDialog(id, '/products/disable', '/products/list')}
                                            fullWidth
                                            style={{ backgroundColor: 'red' }}>
                                            Eliminar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={loading?.update}
                                            fullWidth>
                                            {loading?.update ? (
                                                <CircularProgress size={24} color="inherit" />
                                            ) : (
                                                'Actualizar'
                                            )}
                                        </Button>
                                    </Grid>
                                    {/* Nuevo botón para añadir stock */}
                                    <Grid item xs={4}>
                                        <Button
                                            variant="contained"
                                            color="success"
                                            onClick={() => setOpenAddStockModal(true)} // Este método abrirá el modal
                                            disabled={!productForm.has_stock}
                                            fullWidth>
                                            Añadir stock a cantera
                                        </Button>
                                    </Grid>
                                </Grid>
                                {error.update && (
                                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                                        {error.update}
                                    </Typography>
                                )}
                            </form>
                        </Accordion>
                        {productForm.products.map((product) => (
                            <Accordion key={product.id} title={`${product.code} - ${product.quarry?.name}`}>
                                <Grid container spacing={2} style={{ padding: '20px' }} alignItems="center">
                                    {/* Título Información del producto */}
                                    <Grid item xs={12}>
                                        <Typography variant="h6" gutterBottom>
                                            Información del producto
                                        </Typography>
                                    </Grid>

                                    {/* Campos del formulario */}
                                    <Grid item xs={12} md={2}>
                                        <DecimalTextField
                                            label="Stock"
                                            name="stock"
                                            value={product.stock}
                                            onChange={(e) => handleChangeProduct(product.id, 'stock', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <DecimalTextField
                                            label="Precio"
                                            name="price"
                                            value={product.price}
                                            onChange={(e) => handleChangeProduct(product.id, 'price', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleUpdateProduct(product.id)} // Llamada al endpoint /products/update_product
                                            fullWidth
                                            style={{ height: '100%' }}>
                                            Actualizar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* Tabla de costes */}
                                        <Typography variant="h6" gutterBottom>
                                            Costes del producto
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleOpenNewCostModal(product.id)}
                                            style={{ marginBottom: '20px' }}
                                        >
                                            Crear nuevo coste
                                        </Button>
                                        {product.costs?.length > 0 ? (
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell><b>Código</b></TableCell>
                                                            <TableCell><b>Nombre</b></TableCell>
                                                            <TableCell><b>Cantidad</b></TableCell>
                                                            <TableCell><b>Precio Unitario</b></TableCell>
                                                            <TableCell><b>Total</b></TableCell>
                                                            <TableCell><b>Fecha</b></TableCell>
                                                            <TableCell><b>Acciones</b></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {product.costs.flatMap((cost) => cost.cost_details).map((detail) => (
                                                            <TableRow key={detail.id}>
                                                                <TableCell>{detail.code}</TableCell>
                                                                <TableCell>{detail.name}</TableCell>
                                                                <TableCell>{detail.quantity} {detail.unit}</TableCell>
                                                                <TableCell>{formatPrice(detail.unit_price)}</TableCell>
                                                                <TableCell>{formatPrice(detail.total_price)}</TableCell>
                                                                <TableCell>{formatDate(detail.date_created)}</TableCell>
                                                                <TableCell>
                                                                    <IconButton
                                                                        edge="end"
                                                                        color="secondary"
                                                                        onClick={() => handleOpenDisableDialog(detail.id, '/costs/disable_cost_detail')}
                                                                    >
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        ) : (
                                            <Typography variant="body2" color="textSecondary">
                                                No hay detalles de costos para este producto.
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </Accordion>
                        ))}
                    </Grid>

                    {/* Modal para crear nuevo coste */}
                    <Dialog
                        open={openNewCostModal}
                        onClose={(e) => setOpenNewCostModal(false)}
                        maxWidth="xl"
                        fullWidth
                        PaperProps={{
                            style: { width: '100%', maxHeight: '100%' } // Ajusta el tamaño según sea necesario
                        }}
                    >
                        <DialogContent>
                            <ProductCosts title={'Crear nuevo coste'} costs={newCost} setCosts={setNewCost} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={(e) => setOpenNewCostModal(false)} color="primary">
                                Cerrar
                            </Button>
                            <Button onClick={handleAddCost} variant='contained' color="primary">
                                Enviar Coste
                            </Button>
                        </DialogActions>
                        {error.addCostError && (
                            <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                                {error.addCostError}
                            </Typography>
                        )}
                    </Dialog>
                    {/* Modal para añadir stock a cantera */}
                    <Dialog
                        open={openAddStockModal}
                        onClose={handleCloseNewStockDialog}
                        maxWidth="sm"
                        fullWidth
                    >
                        <DialogTitle>Añadir nuevo stock a cantera</DialogTitle>
                        <DialogContent>
                            <Autocomplete
                                options={quarries} // Aquí mostramos las canteras
                                getOptionLabel={(option) => option.name?.toString() || ''}
                                value={selectedQuarry}
                                onChange={(event, newValue) => setSelectedQuarry(newValue)} // Guardamos la cantera seleccionada
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Seleccionar cantera"
                                        fullWidth
                                        margin='normal'
                                    />
                                )}
                            />
                            <DecimalTextField
                                label="Precio"
                                margin='normal'
                                name="price"
                                value={newStockPrice}
                                onChange={(e) => setNewStockPrice(e.target.value)} // Guardamos el precio
                            />
                            <DecimalTextField
                                label="Stock"
                                margin='normal'
                                name="stock"
                                value={newStockValue}
                                onChange={(e) => setNewStockValue(e.target.value)} // Guardamos el stock
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseNewStockDialog} color="primary">
                                Cancelar
                            </Button>
                            <Button onClick={handleAddStockToQuarry} disabled={!selectedQuarry || !newStockPrice || !newStockValue} variant='contained' color="primary">
                                Aceptar
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <DisableDialog
                        open={openDisableDialog}
                        onClose={() => setOpenDisableDialog(false)}
                        endpoint={endpointToDisable}
                        disableId={disableId}
                        navigateTo={navigateToWhenDisabled}
                        fetch={fetchProduct}
                    />
                </div>)
            }
        </div >
    );
}

export default ProductDetails;
