import React, { useState, useEffect, useCallback } from "react";
import Paginate from '../../components/Paginate';
import { itemsPerPage } from '../../components/Utils';
import { Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import { useSnackbar } from '../../components/SnackbarProvider';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Button,
    Grid,
    CircularProgress,
    Typography,
    Dialog,
    DialogTitle,
    DialogActions
} from "@mui/material";

function ListQuarries() {

    const { api } = useAuth();
    const [error, setError] = useState({});
    const showSnackbar = useSnackbar();
    const [loading, setLoading] = useState(true);
    const [fetchReset, setFetchReset] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(null);

    const [quarries, setQuarries] = useState([]);

    const [name, setName] = useState(null);
    const [code, setCode] = useState(null);

    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('ASC');

    // State for modal
    const [modalOpen, setModalOpen] = useState(false);
    const [modalName, setModalName] = useState('');
    const [modalAddress, setModalAddress] = useState('');
    const [modalDescription, setModalDescription] = useState('');

    const fetchQuarries = useCallback(async () => {
        try {
            setLoading(true);
            const params = {};
            params.limit = itemsPerPage;
            params.offset = (currentPage - 1) * itemsPerPage;

            if (!fetchReset) {
                if (name) params.name = name;
                if (code) params.code = code;
                if (sortColumn) params.sort_by = sortColumn;
                if (sortDirection) params.sort_direction = sortDirection;
            } else {
                setFetchReset(false);
            }

            const result = await api().get("/quarries/", { params });

            setQuarries(result.data[0]);
            setTotalItems(result.data[1]);
            setLoading(false);
        } catch (error) {
            setError(prevError => ({ ...prevError, general: 'Error obteniendo canteras' }));
        }
    }, [api, currentPage, fetchReset, code, name, sortColumn, sortDirection]);

    useEffect(() => {
        fetchQuarries();
    }, [currentPage, sortColumn, sortDirection, fetchReset]);

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
        } else {
            setSortColumn(column);
            setSortDirection('ASC');
        }
        setCurrentPage(1);
    };

    const handleFilters = (action) => {
        setCurrentPage(1);

        if (action === "reset") {
            setName(null);
            setCode(null);
            setFetchReset(true);
        }

        else if (action === "apply") {
            fetchQuarries();
        }
    };

    const cellStyle = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '75px'
    };

    const validateForm = () => {
        const errors = {};

        if (!modalName) {
            errors.name = "Por favor, añada un nombre.";
        } else if (modalName && modalName.length > 100) {
            errors.name = "El nombre no debe exceder los 100 caracteres.";
        }
        if (modalAddress && modalAddress.length > 100) {
            errors.address = "La dirección no debe exceder los 100 caracteres.";
        }
        if (modalDescription && modalDescription.length > 255) {
            errors.description = "La dirección no debe exceder los 255 caracteres.";
        }
        return Object.keys(errors).length > 0 ? errors : null;
    };

    const handleCreateQuarry = async () => {
        let createQuarryError = null;

        try {

            const validationErrors = validateForm();
            if (validationErrors) {
                setError(validationErrors);
                return;
            }

            const result = await api().post('/quarries/', {
                name: modalName,
                address: modalAddress,
                description: modalDescription
            });
            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    setError({ createQuarryError: `Error: ${result.data.detail}` });
                } else {
                    showSnackbar("Cantera creada correctamente");
                    setModalOpen(false);
                    fetchQuarries();
                    setError(null);
                }
            } else {
                createQuarryError = `Error ${result.status}: ${result.data.detail}`;
            }
        } catch (error) {
            createQuarryError = `Error inesperado creando la cantera.`;

        } finally {
            setError(prevError => ({ ...prevError, createQuarryError: createQuarryError }));
            setLoading(false);
        }
    };

    return (
        <div>
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={3} sm={2}>
                                    <TextField
                                        fullWidth
                                        label="Código"
                                        name="code"
                                        value={code || ""}
                                        onChange={(e) => setCode(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <TextField
                                        fullWidth
                                        label="Nombre"
                                        name="name"
                                        value={name || ""}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1} justifyContent="flex-end">
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={() => handleFilters("apply")}>
                                        Aplicar Filtros
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" color="secondary" onClick={() => handleFilters("reset")}>
                                        Resetear Filtros
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                    {/* Create quarry button */}
                    <Grid container>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setModalOpen(true)} // Open the modal
                        >
                            Crear nueva cantera
                        </Button>
                    </Grid>
                    <br />
                    <br />
                    <br />
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('code')} style={{ cursor: 'pointer' }}>
                                                Código {sortColumn === 'code' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('name')} style={{ cursor: 'pointer' }}>
                                                Nombre {sortColumn === 'name' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {quarries.map(item => (
                                    <TableRow key={item.id}>
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            <Link to={`/quarries/${item.id}/details`}>
                                                {item.code || '-'}
                                            </Link>
                                        </TableCell>
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            {(item.name || item.surname) ? `${item.name || ''} ${item.surname || ''}` : '-'}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
            <br />
            <br />
            <br />
            <Paginate
                totalItems={totalItems}
                onPageChange={setCurrentPage}
                currentPage={currentPage}
            />
            <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
                <DialogTitle>Crear cantera</DialogTitle>
                {/* Aquí colocamos el contenido del diálogo */}
                <div style={{ padding: '16px' }}>
                    <TextField
                        label="Nombre de la cantera"
                        value={modalName}
                        onChange={(e) => setModalName(e.target.value)}
                        fullWidth
                        inputProps={{ maxLength: 100 }}
                        margin="normal"
                        error={!!error?.name}
                        helperText={error?.name}
                    />
                    <TextField
                        label="Dirección"
                        value={modalAddress}
                        onChange={(e) => setModalAddress(e.target.value)}
                        fullWidth
                        inputProps={{ maxLength: 100 }}
                        margin="normal"
                        error={!!error?.address}
                        helperText={error?.address}
                    />
                    <TextField
                        label="Descripción"
                        value={modalDescription}
                        onChange={(e) => setModalDescription(e.target.value)}
                        fullWidth
                        inputProps={{ maxLength: 255 }}
                        multiline
                        rows={3}
                        margin="normal"
                        error={!!error?.description}
                        helperText={error?.description}
                    />
                    {error?.createQuarryError && (
                        <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                            {error.createQuarryError}
                        </Typography>
                    )}
                </div>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCreateQuarry}
                        disabled={!modalName || loading}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Crear'}
                    </Button>
                </DialogActions>
            </Dialog>
            {error?.general && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error.general}
                </Typography>
            )}
        </div>
    );
}

export default ListQuarries;
