import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { useSnackbar } from '../../components/SnackbarProvider';
import {
    Typography,
    Paper,
    Grid,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button,
    Dialog,
    DialogActions,
    FormHelperText,
    DialogContent,
    Box,
    DialogTitle,
    TextField,
    Checkbox
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import InvoiceTable from '../../components/InvoiceTable';
import {
    formatDate,
    formatPrice,
    Accordion,
    CreatePdf,
    UploadDocumentButton,
    handleApiError,
    formatNumber,
    formatUnit,
    DisableDialog,
    DecimalTextField,
    DownloadDocumentButton,
    formatProviderOrderStatus
} from '../../components/Utils';

dayjs.extend(utc);

const ProviderOrderDetails = () => {
    const { id } = useParams();
    const { api } = useAuth();
    const showSnackbar = useSnackbar();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [order, setOrder] = useState({});
    const [openInvoice, setOpenInvoice] = useState(false);
    const [quantities, setQuantities] = useState({});
    const [quantityErrors, setQuantityErrors] = useState({});
    const [inputErrors, setInputErrors] = useState({});

    const [selectedDNs, setSelectedDNs] = useState([]);

    const [openDisableDialog, setOpenDisableDialog] = useState(false);
    const [disableId, setDisableId] = useState(null);
    const [navigateToWhenDisabled, setNavigateToWhenDisabled] = useState(false);
    const [endpointToDisable, setEndpointToDisable] = useState(false);

    const [invoices, setInvoices] = useState([]);
    const [totalPriceSum, setTotalPriceSum] = useState(null);
    const [paidAmountSum, setPaidAmountSum] = useState(null);
    const [remainingAmountSum, setRemainingAmountSum] = useState(null);

    const [deliveryNotes, setDeliveryNotes] = useState([]);

    const [invoiceLimitDate, setInvoiceLimitDate] = useState(null);
    const [vat, setVat] = useState(null);

    const [errors, setErrors] = useState({
        vat: '',
        retention: '',
    });

    const fetchOrder = useCallback(async () => {
        try {
            setLoading(true);

            const response = await api().get(`/provider_orders/${id}`);
            const data = response.data;

            setOrder(data.provider_order);
            setDeliveryNotes(data.delivery_notes);
            setInvoices(data.invoices);
            setTotalPriceSum(data.total_price_sum);
            setPaidAmountSum(data.paid_amount_sum);
            setRemainingAmountSum(data.remaining_amount_sum);

            setLoading(false);

        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    }, [api, id]);


    useEffect(() => {
        fetchOrder();
    }, []);

    const handleClickOpenInvoice = () => {
        setSelectedDNs([]);
        setOpenInvoice(true);
    };

    const handleCloseInvoice = () => {
        setVat(null);
        setInvoiceLimitDate(null);
        setOpenInvoice(false);
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'vat') {
            setVat(value)
        }
        const numericValue = Number(value);
        // Verificar que el valor es un número entero y está en el rango permitido
        if (!isNaN(numericValue) && Number.isInteger(numericValue) && numericValue >= 0 && numericValue <= 100) {
            setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, [name]: 'El valor debe ser un número entero entre 0 y 100' }));
        }
    };

    const handleDateChange = (name, newValue) => {
        const date = dayjs(newValue).hour(12).minute(0).second(0).utc().format('YYYY-MM-DD');
        if (name === 'invoice_limit_date') {
            setInvoiceLimitDate(date);
        }
    };
    const handleCreate = async () => {
        let error = null;
        try {
            const delNoteData = {
                provider_order_id: id,
                delivery_note_provider_details: Object.entries(quantities).map(([productId, quantity]) => ({
                    provider_order_detail_id: productId,
                    quantity: parseFloat(quantity)
                }))
            };
            const response = await api().post(`/delivery_note_providers/`, delNoteData);
            const data = response.data;

            setOrder(data.provider_order);
            setDeliveryNotes(data.delivery_notes);
            setQuantities({});
            setError(null);
            showSnackbar('Albarán creado correctamente');

        } catch (e) {
            error = handleApiError(e, 'Error inesperado creando albarán.')
        } finally {
            if (error) showSnackbar(error, 'error');
            setLoading(false);
        }
    };

    const handleCreateInvoice = async () => {
        let error = null;
        try {
            await api().post(`/invoices_provider/`,
                {
                    delivery_notes: selectedDNs,
                    vat: vat,
                    invoice_limit_date: invoiceLimitDate
                });

            showSnackbar('Factura creada correctamente');
            fetchOrder();
            handleCloseInvoice(false);
            setError(null);

        } catch (e) {
            error = handleApiError(e, 'Error inesperado creando factura.')
        } finally {
            if (error) showSnackbar(error, 'error');
            setLoading(false);
        }
    };

    const handleQuantityChange = (id, value) => {
        const orderedQuantity = order.provider_order_details.find(item => item.id === id).quantity;
        const receivedQuantity = order.provider_order_details.find(item => item.id === id).received_quantity || 0;

        setQuantities(prevQuantities => ({
            ...prevQuantities,
            [id]: value
        }));

        const quantity = parseFloat(value);

        let error = false;
        if (quantity < 0 || quantity > orderedQuantity || receivedQuantity + quantity > orderedQuantity) {
            error = true;
        }

        setQuantityErrors(prevErrors => ({
            ...prevErrors,
            [id]: error
        }));

    };

    const handleDNChange = (id) => {
        setSelectedDNs(prevSelected =>
            prevSelected.includes(id)
                ? prevSelected.filter(albaranId => albaranId !== id)
                : [...prevSelected, id]
        );
    };

    const handleOpenDisableDialog = (itemId, endpoint, navigateTo) => {
        setDisableId(itemId);
        setEndpointToDisable(endpoint);
        setNavigateToWhenDisabled(navigateTo);
        setOpenDisableDialog(true);
    };

    const hasErrors = Object.values(quantityErrors).some(error => error);
    const allQuantitiesFilled = Object.values(quantities).some(qty => qty !== 0 && qty !== '');
    const hasInputErrors = Object.values(inputErrors).some(error => error);
    const hasUninvoicedDNs = deliveryNotes.some(dn => !dn.invoiced);

    return (
        <div style={{ padding: 20 }}>
            <Grid container spacing={3} style={{ position: 'fixed', top: 60, right: 10, zIndex: 1100, width: 'auto' }}>
                <Grid item>
                    <Paper elevation={0} style={{ padding: '10px', textAlign: 'center', backgroundColor: '#fff' }}>
                        <Box mb={2}>
                            <Typography variant="h5" component="h1" gutterBottom>
                                {order.code}
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            <Accordion title={`Detalles del pedido ${order.code} `}>
                <Grid container spacing={3} style={{ marginBottom: 20 }}>
                    <Grid item xs={3}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>FECHA DEL PEDIDO:</Typography>
                        <Typography variant="body1">
                            {formatDate(order.order_date) || '-'}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>ESTADO DEL PEDIDO:</Typography>
                        <Typography variant="body1">
                            {formatProviderOrderStatus(order.provider_order_status)}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} >
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>VER PEDIDO:</Typography>
                        <CreatePdf endpoint={`/provider_orders/${id}/generate-pdf`}></CreatePdf>
                    </Grid>
                    <Grid item xs={3} >
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>BORRAR EL PEDIDO:</Typography>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handleOpenDisableDialog(order.id, '/provider_orders/disable', '/provider_orders/list')}
                        >
                            Borrar
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>IMPORTE TOTAL:</Typography>
                        <Typography variant="body1" >
                            {formatPrice(order.total_price)}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>PROVEEDOR:</Typography>
                        <Typography variant="body1">
                            <Link to={`/providers/${order.provider?.id}/details`} >
                                {order.provider?.code || '-'}
                            </Link>
                        </Typography>
                        <Typography variant="body1">{order.provider?.name || '-'}</Typography>
                        <Typography variant="body1">{order.provider?.telephone_number || '-'}</Typography>
                    </Grid>
                </Grid>

                <Typography variant="h5" gutterBottom>
                    Productos del pedido
                </Typography>
                <Button variant="contained" color="primary" disabled={hasErrors || !allQuantitiesFilled || hasInputErrors} onClick={handleCreate} style={{ marginTop: 20 }}>
                    Crear albarán
                </Button>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nombre del Producto</TableCell>
                                <TableCell>Importe</TableCell>
                                <TableCell>Precio individual</TableCell>
                                <TableCell>Cantidad pedida</TableCell>
                                <TableCell>Cantidad recibida</TableCell>
                                <TableCell>Cantidad a recibir</TableCell>
                                <TableCell>Estado</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {order.provider_order_details?.map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell>{item.product_group.name}</TableCell>
                                    <TableCell>
                                        {formatPrice(item.unit_price * item.quantity)}
                                    </TableCell>
                                    <TableCell>
                                        {formatPrice(item.unit_price)}
                                    </TableCell>
                                    <TableCell>{formatNumber(item.quantity)} {formatUnit(item.product_group.unit)}
                                    </TableCell>
                                    <TableCell>{formatNumber(item.received_quantity)} {formatUnit(item.product_group.unit)}
                                    </TableCell>
                                    <TableCell>
                                        <DecimalTextField
                                            value={quantities[item.id] || ''}
                                            onChange={(e) => handleQuantityChange(item.id, e.target.value)}
                                            inputProps={{ min: 0, max: item.quantity }}
                                            fullWidth
                                            disabled={item.received_quantity === item.quantity}
                                            error={quantityErrors[item.id] || inputErrors[item.id] || false} // Add input error check
                                        />
                                        {(quantityErrors[item.id] || inputErrors[item.id]) && (
                                            <FormHelperText error>
                                                Valor no válido
                                            </FormHelperText>
                                        )}

                                    </TableCell>
                                    <TableCell>
                                        {formatProviderOrderStatus(item.provider_order_detail_status)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {error && (
                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                        {error}
                    </Typography>
                )}

            </Accordion>
            <Accordion title='Albaranes'>
                <Button variant="contained" color="primary" style={{ marginTop: 20 }} onClick={handleClickOpenInvoice} disabled={!hasUninvoicedDNs}>
                    Crear factura
                </Button>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Código</TableCell>
                                <TableCell>Facturado</TableCell>
                                <TableCell>Fecha</TableCell>
                                <TableCell>Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {deliveryNotes.filter(item => !item.disabled).map(item => (
                                <TableRow key={item.id}>
                                    <TableCell>
                                        <Link to={`/provider_delivery_notes/${item.id}/details`}>
                                            {item.code || '-'}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{item.invoiced ? "Sí" : "No"}</TableCell>
                                    <TableCell>{formatDate(item.dn_date)}</TableCell>
                                    <TableCell>
                                        {item.documents && item.documents.length > 0 && (() => {
                                            const activeDocument = item.documents.find(doc => !doc.disabled);
                                            return activeDocument ? (
                                                <DownloadDocumentButton documentId={activeDocument.id} documentName={activeDocument.name} />
                                            ) : null;
                                        })()}
                                        <UploadDocumentButton
                                            to={'delivery_note_provider_id'}
                                            id={item.id}
                                            fetch={fetchOrder}
                                        >
                                        </UploadDocumentButton>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleOpenDisableDialog(
                                                item.id, 
                                                '/delivery_note_providers/disable')}
                                            sx={{ ml: 2 }}
                                        >
                                            Borrar
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Accordion>

            <InvoiceTable
                isProviderInvoice={true}
                showBuilding={false}
                showClientOrProvider={false}
                title='Facturas'
                totalAmount={totalPriceSum}
                paidAmount={paidAmountSum}
                remainingAmountSum={remainingAmountSum}
                invoices={invoices}
                uploadDocument={true}
                fetch={fetchOrder}
                handleOpenDeleteDialog={handleOpenDisableDialog}
            />

            <Dialog open={openInvoice} onClose={handleCloseInvoice}>
                <DialogTitle>Crear Factura</DialogTitle>
                <DialogContent>
                    <br />
                    <Grid>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="es"
                            localeText={{
                                cancelButtonLabel: "cancelar",
                                okButtonLabel: "Ok",
                                datePickerToolbarTitle: 'Seleccionar',
                            }}>
                            <MobileDatePicker
                                label="Fecha vencimiento factura"
                                closeOnSelect={true}
                                onChange={(newValue) => handleDateChange('invoice_limit_date', newValue)}
                                renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid >
                        <TextField
                            name="vat"
                            label="IVA (%)"
                            fullWidth
                            value={vat}
                            onChange={handleChange}
                            margin="normal"
                            type="number"
                            error={!!errors.vat}
                            helperText={errors.vat}
                        />
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Seleccionar</TableCell>
                                    <TableCell>Código</TableCell>
                                    <TableCell>Fecha</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {deliveryNotes.filter(item => !item.invoiced).map((item) => (
                                    <TableRow key={item.id}>
                                        <TableCell>
                                            <Checkbox
                                                checked={selectedDNs.includes(item.id)}
                                                onChange={() => handleDNChange(item.id)}
                                            />
                                        </TableCell>
                                        <TableCell>{item.code}</TableCell>
                                        <TableCell>{item.dn_date}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseInvoice} color="secondary">
                        Cancelar
                    </Button>
                    <Button onClick={handleCreateInvoice} disabled={!!errors.vat || !vat || selectedDNs.length <= 0} color="primary">
                        Crear factura
                    </Button>
                </DialogActions>
            </Dialog>

            <DisableDialog
                open={openDisableDialog}
                onClose={() => setOpenDisableDialog(false)}
                endpoint={endpointToDisable}
                disableId={disableId}
                navigateTo={navigateToWhenDisabled}
                fetch={fetchOrder}
            />
        </div>
    );
};

export default ProviderOrderDetails;
